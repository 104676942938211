<template>
  <div class="company-logos">
    <a
      :href="company.url"
      target="_blank"
      v-for="(company, index) of companies"
      :key="index"
      class="mr-4"
    >
      <img :src="url(company.logo)" class="company-logo" :class="size" />
    </a>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { Company } from "../../../models";
import { urlFromMedia } from "@/utils/UrlHelper";
import Media from "@/models/strapi/Media";

@Component({})
export default class Logos extends Vue {
  @Prop() readonly companies!: Company[];
  @Prop({ default: "md" }) readonly size!: "md" | "lg" | "xs" | "xxl";

  url(media: Media) {
    return urlFromMedia(media);
  }
}
</script>

<style lang="scss" scoped>
.company-logo {
  object-fit: contain;

  &.lg {
    width: 130px;
  }

  &.xxl {
    width: 120px;
    height: 120px;
  }
  &.md {
    width: 60px;
    height: 60px;
  }

  &.sm {
    width: 40px;
    height: 40px;
  }
}
</style>
