import User from "../models/User";
import axios from "axios";
import { Parser } from "../models/Model";
import {
  STRAPI_BASE_API_URL,
  CLOUD_ENABLING_BASE_API_URL,
} from "../utils/Constants";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  stateFactory: true,
  namespaced: false,
  name: "currentUser",
})
export default class CurrentUser extends VuexModule {
  _user: User | undefined = undefined;

  get currentUser() {
    return this._user;
  }

  get user() {
    return this._user;
  }

  @Mutation
  setCurrentUser(payload: User | undefined) {
    this._user = payload;
  }

  @Action({ rawError: true })
  async loadCurrentUser(options: any = {}) {
    const { commit } = this.context;
    try {
      const response = await axios({
        url: CLOUD_ENABLING_BASE_API_URL + "/current_user",
        ...options,
      });
      const user = Parser.parse(response.data) as User;
      if (!user) {
        throw new Error("Current user not found");
      }
      commit("setCurrentUser", user);
      return user;
    } catch (e) {
      commit("setCurrentUser", undefined);
      throw e;
    }
  }
}
