<template>
  <div class="chat-bot">
    <div class="chat-bot-icon">
      <v-icon
        size="lg"
        :style="iconStyle"
        class="clickable"
        ref="chatBotIcon"
        @click.native.stop="toggleOpen"
        >chatbot</v-icon
      >
    </div>

    <transition name="slide">
      <div class="chat-bot--content" v-click-outside="toggleOpen" v-if="open">
        <div
          class="chat-bot--content--title d-flex justify-content-between align-items-center w-100 px-3 py-2"
        >
          <div class="font-size-14 font-weight-semibold">
            <span>virtual assistant</span>
          </div>
          <v-svg
            class="clickable icon-white"
            code="it-close"
            @click.native.stop="toggleOpen"
          />
        </div>
        <div class="chat-bot--content--faq-container px-3" ref="chatContainer">
          <template v-if="domainLevel">
            <div
              v-for="(message, index) in messages"
              :key="index"
              class="d-flex"
              :class="[
                { 'justify-content-end': message.type === 'user' },
                { 'justify-content-start': message.type === 'bot' },
              ]"
            >
              <div
                :class="`message message--${message.type} mt-2 justify-content-end`"
                v-if="message.type === 'bot' || message.type === 'user'"
              >
                <span class="font-weight-semibold">{{ message.text }}</span>
              </div>
              <div v-else>
                <div
                  class="chat-bot--content--faq-container--faq mt-2 py-2 px-3 text-custom-primary text-left font-weight-semibold clickable"
                  v-for="domain in domains"
                  :key="domain.id"
                  @click.stop="loadFaqs(domain)"
                >
                  {{ domain.domain }}
                </div>
              </div>
            </div>

            <div v-if="loading" class="d-flex justify-content-start">
              <spinner size="sm" />
            </div>
          </template>

          <template v-else-if="faqLevel">
            <div
              class="d-flex align-items-center px-3 py-2 text-custom-primary separation-border clickable font-weight-semibold"
              @click.stop="changeLevel(0)"
              v-if="currentDomain"
            >
              <v-svg code="it-arrow-left" class="icon-primary arrow-icon" />
              <div class="ml-2">
                <span>{{ currentDomain.domain }}</span>
              </div>
            </div>
            <div
              class="chat-bot--content--faq-container--faq px-3 py-2 font-weight-semibold clickable separation-border text-left"
              v-for="faq in faqsToShow"
              :key="faq.id"
              @click.stop="loadAnswer(faq)"
            >
              {{ faq.question }}
            </div>
          </template>

          <template v-else-if="answerLevel">
            <div
              class="d-flex align-items-center px-3 py-2 text-custom-primary separation-border clickable"
              @click.stop="changeLevel(1)"
            >
              <v-svg
                code="it-arrow-left"
                class="icon icon-primary arrow-icon"
              />
              <div class="ml-2 font-weight-semibold">
                <span>Torna indietro</span>
              </div>
            </div>
            <div class="px-3 py-2">
              <div class="w-100 mt-3 font-weight-bold text-left">
                {{ currentFaq.question }}
              </div>
              <p v-html="markedAnswer" class="mt-3 text-left"></p>
            </div>
          </template>
        </div>

        <form
          class="border-top p-3 bg-white chat-bot--content--chat"
          @submit.prevent="sendMessage"
          v-if="domainLevel"
        >
          <div class="d-flex justify-content-between align-items-center">
            <input
              type="text"
              class="form-control border"
              v-model="userMessage"
            />
            <button
              class="btn btn-primary ml-1 send-button my-auto"
              :disabled="loading"
            >
              <v-svg code="it-arrow-right" class="icon-white" />
            </button>
          </div>
          <div class="font-size-14 text-left text-muted mt-1">
            Premi invio per inviare il messaggio
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import VIcon from "@/components/VIcon.vue";
import VSvg from "@/components/Common/VSvg.vue";
import Faq from "@/models/strapi/Faq";
import FaqsMixin from "@/mixins/http/strapi/FaqsMixin";
import FaqDomain from "@/models/strapi/FaqDomain";
import { safeAsync } from "@/utils/AsyncUtil";
import ClickOutside from "@/directives/ClickOutside";
import { zoneStore } from "@/store/typed";
import Spinner from "@/components/Common/Spinner.vue";
import marked from "marked";
import Throttler from "@/utils/Throttler";

export enum ChatBotLevel {
  DomainLevel,
  FaqLevel,
  AnswerLevel,
}

export interface ChatMessage {
  text: string | null;
  type: "user" | "bot" | "suggestions";
}

@Component({
  components: {
    VIcon,
    VSvg,
    Spinner,
  },
  directives: {
    ClickOutside,
  },
})
export default class ChatBot extends Mixins(FaqsMixin) {
  private open = false;

  private faqDomains: FaqDomain[] = [];

  private faqsToShow: Faq[] = [];
  private currentDomain: FaqDomain | null = null;
  private currentFaq: Faq | null = null;

  private currentLevel = ChatBotLevel.DomainLevel;

  private userMessage = "";
  private messages: ChatMessage[] = [
    { type: "bot", text: "Ciao, di cosa hai bisogno?" },
  ];

  @Ref() readonly chatContainer!: HTMLElement;
  @Ref() readonly chatBotIcon!: HTMLElement;
  @Prop() readonly iconStyle!: any;

  private throttler = new Throttler();
  private loading = false;

  get domainLevel() {
    return this.currentLevel === ChatBotLevel.DomainLevel;
  }

  get faqLevel() {
    return this.currentLevel === ChatBotLevel.FaqLevel;
  }

  get answerLevel() {
    return this.currentLevel === ChatBotLevel.AnswerLevel;
  }

  get faqs() {
    return zoneStore.zone.faq;
  }

  get domains() {
    return this.faqDomains;
  }

  get markedAnswer() {
    return marked(this.currentFaq.answer);
  }

  created() {
    this.loadDomains();
  }

  private toggleOpen() {
    this.open = !this.open;

    if (!this.open) {
      this.currentLevel = ChatBotLevel.DomainLevel;
    }
  }

  private changeLevel(level: number) {
    this.currentLevel = level;

    if (level === ChatBotLevel.DomainLevel) {
      this.scrollTo(this.chatContainer.scrollHeight);
    } else if (level === ChatBotLevel.FaqLevel) {
      this.scrollTo(0);
    }
  }

  private async loadDomains() {
    const [data, errors] = await safeAsync(this.getDomains({}));

    if (data) {
      this.faqDomains = [...data];
    }
  }

  private async loadFaqs(domain: FaqDomain) {
    this.currentDomain = domain;
    this.faqsToShow = this.faqs.filter(
      (faq: Faq) => faq.faq_domain.id === domain.id,
    );
    this.changeLevel(ChatBotLevel.FaqLevel);
  }

  private loadAnswer(faq: Faq) {
    this.currentFaq = faq;
    this.currentLevel = ChatBotLevel.AnswerLevel;
  }

  private sendMessage() {
    if (!this.userMessage) {
      return;
    }

    this.messages.push({ text: this.userMessage, type: "user" });

    this.loading = true;

    this.$nextTick(() => {
      this.chatContainer.scrollTop = this.chatContainer.scrollHeight;
    });

    this.throttler.run(() => {
      this.messages.push({
        text: "Ecco un elenco di argomenti che potrebbero soddisfare la tua richiesta",
        type: "bot",
      });

      this.messages.push({ text: null, type: "suggestions" });

      this.loading = false;
      this.scrollTo(this.chatContainer.scrollHeight);
      this.userMessage = "";
    });
  }

  private scrollTo(value: number) {
    this.$nextTick(() => {
      this.chatContainer.scrollTop = value;
    });
  }
}
</script>

<style lang="scss">
@import "@/styles/vue";

$icon-size: 44px;

$content-width: 350px !default;
$content-height: 310px !default;

$icon-positioning-bottom: 20px;
$icon-positioning-right: 20px;

.chat-bot {
  .chat-bot-icon {
    .icon {
      position: fixed;
      z-index: $zindex-tooltip;
      right: $icon-positioning-right;
      transition: bottom 0.2s ease 0s;

      > svg {
        width: $icon-size !important;
        height: $icon-size !important;
        color: white !important;
      }
    }
  }

  &--content {
    position: fixed;
    right: $icon-positioning-right + ($icon-size/10);
    bottom: $icon-positioning-bottom + ($icon-size + 16px);
    z-index: $zindex-tooltip + 10;
    box-shadow: 0px 3px 15px #5c6f8229;
    width: $content-width;

    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 100%;
      right: 0px;
      max-height: 100%;
      bottom: 0px;
      transform: translateY(8px) !important;
    }

    &--title {
      background-color: $primary;
      color: white;
      text-transform: uppercase;
      border-radius: 5px 5px 0 0;
    }

    &--faq-container {
      background-color: white;
      height: $content-height;
      overflow: scroll;

      &--faq {
        &:hover {
          background-color: #f0f6fc;
        }
      }
    }

    &--chat {
      border-radius: 0 0 5px 5px;
    }
  }

  .separation-border {
    border-bottom: 1px solid $gray-100;
  }

  .arrow-icon {
    width: 22px !important;
    height: 22px !important;
  }

  .send-button {
    padding: 6.75px 9px !important;
  }

  .message {
    border-radius: 5px;
    max-width: 200px;
    font-size: 14px;
    padding: 0.3rem 0.5rem;
    overflow-wrap: break-word;

    &--bot {
      background-color: $custom-gray-100;
    }

    &--user {
      background-color: $hex-primary;
      color: white;
    }
  }

  .slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to,
  .slide-leave {
    max-height: 600px;
    overflow: hidden;
  }

  .slide-enter,
  .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }
}
</style>
