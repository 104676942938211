<template>
  <div class="nav-item dropdown d-flex">
    <p
      class="nav-link dropdown-toggle font-size-14 px-0 mb-0 text-white my-auto"
      href="#"
      :aria-expanded="open.toString()"
      @click.stop="toggleOpen"
      v-click-outside="closeDropdown"
    >
      <v-svg v-if="isMobile" code="it-user" class="icon-white m-auto pl-2" />
      {{ isMobile ? "" : logged.label }}
      <v-svg
        v-if="!isMobile"
        class="icon icon-sm icon-white"
        code="it-expand"
      />
    </p>
    <div
      class="dropdown-menu px-4"
      :class="[{ 'right-caret': isMobile }, { show: open }]"
    >
      <div class="row">
        <div class="col-12 px-0">
          <div class="link-list-wrapper">
            <ul class="link-list">
              <li class="clickable list-item mb-2" v-if="isAdmin">
                <router-link
                  tag="p"
                  class="list-item mb-0"
                  :to="{ name: 'admin_area' }"
                >
                  <span>Area admin</span>
                </router-link>
              </li>
              <li class="clickable list-item mb-2">
                <router-link
                  tag="p"
                  class="list-item mb-0"
                  :to="{ name: securedHome }"
                >
                  <span>Area riservata</span>
                </router-link>
              </li>
              <li @click="logout" class="clickable">
                <p class="list-item mb-0">
                  <span>Logout</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { currentUserStore, authStore } from "@/store/typed";

import ClickOutside from "@/directives/ClickOutside";

@Component({
  directives: {
    ClickOutside,
  },
})
export default class LogoutDropdown extends Vue {
  @Prop({ default: false }) readonly isMobile!: boolean;

  private open = false;

  get logged() {
    return currentUserStore.currentUser;
  }

  get isAdmin() {
    return this.logged.isAdmin || this.logged.isSuperAdmin;
  }

  logout() {
    authStore.logout();
    this.$router.push({ name: "login" });
  }

  toggleOpen() {
    this.open = !this.open;
  }

  get securedHome() {
    return this.isOccUser ? "watcher_area_home" : "info_home";
  }

  get isOccUser() {
    return currentUserStore.currentUser.isOccUser;
  }

  closeDropdown() {
    this.open = false;
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/vue";

.dropdown {
  cursor: pointer;
  @include media-breakpoint-down(md) {
    .dropdown-menu.show {
      position: fixed;
      left: calc(100vw - 168px);
      top: 53px;
      width: 163px;
    }
  }
}

.dropdown-menu.right-caret::before {
  right: 24px !important;
  left: initial !important;
}
</style>
