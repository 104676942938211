import { configuratorStore } from "@/store/typed";
import BaseService, { ServiceCode } from "./BaseService";

export default class Service2 extends BaseService {
  zone1Value: number;
  zone2Value: number;
  zone3Value: number;
  readonly code: ServiceCode = "s2";

  cloudServiceValue: number;
  publicCloudServiceValue: number;
  dataManagementValue: number;
  extraStrategies: number;

  constructor() {
    super();
    this.name = "Servizio S2 – Strategia di migrazione";
    this.percentage = 0.01;

    this.zone1Value = 0;
    this.zone2Value = 0;
    this.zone3Value = 0;
    this.cloudServiceValue = 0;
    this.publicCloudServiceValue = 0;
    this.dataManagementValue = 0;
    this.extraStrategies = 0;
  }

  get total() {
    return this.crossProjects + this.digitalProjects;
  }

  get maxValue() {
    return this.total * this.percentage;
  }

  get crossProjects() {
    return (
      this.cloudServiceValue +
      this.publicCloudServiceValue +
      this.dataManagementValue +
      this.extraStrategies
    );
  }

  get workingDays() {
    return Math.floor(this.maxValue / this.dailyCost);
  }

  get workingFrequency() {
    if (!this.months) {
      return 0;
    }
    return Math.floor(this.workingDays / this.months);
  }

  get digitalProjects() {
    return this.zone1Value + this.zone2Value + this.zone3Value;
  }

  get complete() {
    // return !!(
    //   (this.zone1Value || this.zone2Value) &&
    //   this.crossProjects > 0
    // );
    return this.digitalProjects > 0
      ? this.crossProjects > 0
      : this.digitalProjects + this.crossProjects > 0;
  }

  get serviceDailyCost() {
    return this.dailyCost;
  }

  get servicePercentage() {
    return this.percentage;
  }

  get crossProjectsSum() {
    return (
      this.cloudServiceValue +
      this.publicCloudServiceValue +
      this.dataManagementValue +
      this.extraStrategies
    );
  }

  get digitalTransProjectsSum() {
    return this.zone1Value + this.zone2Value + this.zone3Value;
  }

  serialize(): Record<string, string | number | boolean> {
    const data = {
      zone1Value: this.zone1Value,
      zone2Value: this.zone2Value,
      zone3Value: this.zone3Value,
      cloudServiceValue: this.cloudServiceValue,
      publicCloudServiceValue: this.publicCloudServiceValue,
      dataManagementValue: this.dataManagementValue,
      extraStrategies: this.extraStrategies,
      months: this.months,
      total: this.total,
      maxValue: this.maxValue,
      workingDays: this.workingDays,
      complete: this.complete,
      workingFrequency: this.workingFrequency,
    };

    this.labels.forEach((label: { label: string; key: string }) => {
      (data as any)[`${label.key}Label`] = label.label;
    });
    return data;
  }

  get labels(): { label: string; key: string }[] {
    return [
      {
        label: "Valore progetti Servizi applicativi in ottica Cloud",
        key: "cloudServiceValue",
      },
      {
        label: "Valore progetti Public Cloud",
        key: "publicCloudServiceValue",
      },
      {
        label: "Valore progetti Data Management",
        key: "dataManagementValue",
      },
      {
        label:
          "Valore progetti relativi ad eventuali ulteriori iniziative strategiche",
        key: "extraStrategies",
      },
      {
        label: "Mesi di attività del servizio (facoltativo)",
        key: "months",
      },

      {
        label: "Valore dei progetti ricadenti nell’ambito del lotto 1",
        key: "zone1Value",
      },
      {
        label: "Valore dei progetti ricadenti nell’ambito del lotto 2",
        key: "zone2Value",
      },
      {
        label: `Valore dei progetti ricadenti nell'ambito del ${configuratorStore.fieldLabel}`,
        key: "zone3Value",
      },
    ];
  }

  deserialize(data: Record<string, number>): Service2 {
    this.zone1Value = data?.zone1Value || 0;
    this.zone2Value = data?.zone2Value || 0;
    this.zone3Value = data?.zone3Value || 0;
    (this.cloudServiceValue = data?.cloudServiceValue || 0),
      (this.publicCloudServiceValue = data?.publicCloudServiceValue || 0),
      (this.dataManagementValue = data?.dataManagementValue || 0),
      (this.extraStrategies = data?.extraStrategies || 0),
      (this.months = data?.months || 0);
    return this;
  }
}
