<template>
  <div class="page-section">
    <div
      class="text-left my-4 clickable pt-4"
      :class="{ container: withPadding }"
      v-if="backLink"
    >
      <a @click="back" class="go-back">
        <v-svg class="icon icon-sm icon-primary mr-2" code="it-arrow-left" />
        Torna indietro</a
      >
    </div>
    <div
      :class="{
        'py-2 py-md-3': backLink && withPadding,
        ' py-3 py-md-4': !backLink && withPadding,
        container: withPadding,
      }"
    >
      <div :class="!actionAlwaysOnSide ? 'd-md-flex d-column-flex' : 'd-flex'">
        <div class="my-auto">
          <p class="align-self-center my-auto" :class="titleClass" v-if="title">
            <v-svg
              :code="icon"
              v-if="icon"
              class="icon icon-md icon-700 mr-2 mb-2"
            />
            {{ title }}
          </p>
          <slot name="subtitle">
            <p v-if="subtitle">{{ subtitle }}</p>
          </slot>
        </div>
        <div class="ml-auto my-auto">
          <slot name="right-top-action"></slot>
        </div>
      </div>
      <div :class="{ 'mt-4': withPadding }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class PageSection extends Vue {
  @Prop({ default: "" }) readonly title!: string;
  @Prop({ default: "" }) readonly subtitle!: string;
  @Prop({ default: true }) readonly withPadding!: boolean;
  @Prop({ default: "lg" }) readonly titleSize!: "sm" | "md" | "lg" | "xs";
  @Prop() readonly icon?: string;
  @Prop({ default: false }) readonly backLink!: boolean;
  @Prop({ default: false }) readonly backTo!: string;
  @Prop({ default: true }) readonly actionAlwaysOnSide!: boolean;

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  back() {
    if (this.backTo) {
      this.$router.push({ name: this.backTo });
    } else {
      this.$router.back();
    }
  }

  get previousRoute() {
    return this.backTo || -1;
  }

  get titleClass() {
    return this.isMobile
      ? "section-title section-title--xs"
      : `section-title section-title--${this.titleSize}`;
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/vue";
.section-title {
  font-weight: bold;

  &--xs {
    font-size: 20px !important;
    text-align: left;
    font-weight: 300 !important;
  }

  &--sm {
    font-size: 24px !important;
    text-align: left;
    font-weight: 300 !important;
  }

  &--md {
    font-size: 28px !important;
    text-align: left;
  }

  &--lg {
    font-size: 32px !important;
    text-align: left;
  }
}

@include media-breakpoint-down(md) {
  .container {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
</style>
