
export type ServiceCode = "s1" | "s2" | "s3" | "s4";

export default class BaseService {
  code: ServiceCode;
  dailyCost: number;
  expectedValue: number;
  disabled: boolean;
  months: number;
  name: string;
  tooltip?: string;
  percentage: number;

  constructor() {
    this.expectedValue = 0;
    this.name = "";
    this.months = 0;
    this.disabled = false;
    this.percentage = 0;
  }
}
