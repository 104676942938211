<template>
  <footer class="it-footer">
    <div class="it-footer-main py-5">
      <div class="container">
        <div class="row mx-0">
          <div class="col-12 my-auto pt-4 pt-md-0 pl-0 pl-md-5">
            <company-logos :companies="companies" :size="currentBreakpoint" />
          </div>
          <div class="col-12 my-auto justify-content-center">
            <social-links class="ml-auto mt-3 my-md-auto" />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="separator border-bottom"></div>
    </div>
    <div class="it-footer-small-prints clearfix">
      <div class="container d-flex">
        <h3 class="sr-only">Sezione Link Utili</h3>
        <ul
          class="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row px-0"
        >
          <li class="list-inline-item">
            <router-link v-if="!isToolPage" :to="dynamicPageLink('privacy_page')"
              >Privacy policy</router-link
            >
            <a href="#" v-else>Privacy policy</a>
          </li>
          <li class="list-inline-item text-left">
            <router-link v-if="!isToolPage" :to="dynamicPageLink('cookie_page')"
              >Cookie policy</router-link
            >
            <a href="#" v-else>Cookie policy</a>
          </li>
        </ul>
        <ul
          class="text-right it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row ml-auto px-0"
        >
          <!-- <li class="list-inline-item mr-0">
            <router-link :to="dynamicPageLink('sitemap_page')"
              >Mappa del sito</router-link
            >
          </li> -->
          <li class="list-inline-item">
            <router-link v-if="!isToolPage" :to="{ name: 'contact-us' }">Contattaci</router-link>
            <a href="#" v-else>Contattaci</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { currentUserStore, zoneStore } from "../../store/typed";
import SocialLinks from "@/components/Common/SocialLinks.vue";
import CompanyLogos from "@/components/Strapi/Company/Logos.vue";
@Component({ components: { SocialLinks, CompanyLogos } })
export default class TheFooter extends Vue {
  get logged() {
    return currentUserStore.currentUser;
  }

  get companies() {
    return zoneStore.zone?.companies;
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get currentBreakpoint() {
    return this.$mq || "md";
  }

  dynamicPageLink(page: string) {
    return { name: "dynamic_page", params: { page: page } };
  }

  get logo() {
    return require("@/assets/icons/logo_footer.png");
  }

  get isToolPage() {
    return this.$route.name === "tool_show";

  }

  goToLogin() {
    alert("TODO");
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/vue";

@include media-breakpoint-down(md) {
  .container {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.it-brand-logo {
  max-width: 90px;
}

.list-inline-item {
  a {
    font-size: 14px;
    &:hover {
      font-size: 14px;
    }
  }
}

.it-footer-main {
  background: initial;
  border-top: 1px solid #e8f2fc;
}
</style>
