<template>
  <div id="app" ref="app">
    <container :toolRouteIsActive="routeIsTool">
      <router-view />
      <chat-bot :iconStyle="chatbotStyle" v-if="!routeIsTool" />
    </container>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Container from "@/views/Container.vue";
import { zoneStore } from "@/store/typed";
import MetaTag from "@/models/strapi/MetaTag";

import ChatBot from "@/components/Common/ChatBot.vue";

const TITLE_DOMAIN_MAP: { [ k: string ]: string } = {
  'assessment-toolkit.it': 'Assessment Toolkit',
  'www.assessment-toolkit.it': 'Assessment Toolkit',
  'migration-strategy.it': 'Migration Strategy',
  'www.migration-strategy.it': 'Migration Strategy',
  'cloud-feasibility.it': 'Cloud Feasibility',
  'www.cloud-feasibility.it': 'Cloud Feasibility',
  'pmo-toolkit.it': 'PMO Toolkit',
  'www.pmo-toolkit.it': 'PMO Toolkit',
  'myracle-platform.it': 'Myracle Platform',
  'www.myracle-platform.it': 'Myracle Platform',
  'intelligent-knowledge-management.it': 'Intelligent Knowledge Management',
  'www.intelligent-knowledge-management.it': 'Intelligent Knowledge Management',
  'matter-platform.it': 'Matter Platform',
  'www.matter-platform.it': 'Matter Platform',
};

@Component({
  components: { Container, ChatBot },
  metaInfo() {
    return {
      title: this.titleTag,
      meta: this.meta,
    };
  },
})
export default class App extends Vue {
  closeToBottom = false;

  get zone() {
    return zoneStore.zone;
  }
  get metaTags(): MetaTag[] {
    return this.zone?.meta_tags;
  }

  get routeIsTool() {
    return this.$route.name === "tool_show";
  }

  created() {
    window.addEventListener("scroll", this.onScroll);
  }

  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  }

  get chatbotStyle() {
    return this.closeToBottom
      ? { bottom: `${this.isMobile ? 100 : 70}px` }
      : { bottom: "20px" };
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  onScroll() {
    const distanceFromBottom =
      (this.$refs.app as any).clientHeight -
      window.scrollY -
      window.innerHeight;
    this.closeToBottom = distanceFromBottom < 100;
  }

  get meta() {
    let meta: {
      name: string;
      property: string;
      content: string;
    }[] = this.metaTags?.map((item) => {
      return {
        name: item.key,
        property: item.key,
        content: item.value,
      };
    });
    return meta;
  }

  get titleTag() {
    const host = window.location.host;

    if (TITLE_DOMAIN_MAP.hasOwnProperty(host)) {
      return TITLE_DOMAIN_MAP[host];
    }

    if (!this.zone) {
      return "Cloud Enabling";
    }
    return (
      (this.zone?.header_text || "") +
      " - " +
      (this.zone?.sub_header_text || "")
    );
  }
}
</script>

<style lang="scss">
#app {
  text-align: center;
}
</style>
