<template>
  <div class="Container d-flex flex-column min-vh-100">
    <the-navbar :toolRouteIsActive="toolRouteIsActive" />
    <div class="flex-grow-1 body-container">
      <slot> </slot>
    </div>
    <the-footer />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import TheNavbar from "@/components/Common/TheNavbar.vue";
import TheFooter from "@/components/Common/TheFooter.vue";
import { zoneStore, currentUserStore } from "@/store/typed";
import { safeAsync } from "@/utils/AsyncUtil";

@Component({ components: { TheNavbar, TheFooter } })
export default class Container extends Vue {
  @Prop() toolRouteIsActive!: boolean;

  async created() {
    const [zone, zoneErrors] = await safeAsync(zoneStore.loadZone());
    const [news, newsErrors] = await safeAsync(zoneStore.loadNews());
    const [parameters, parametersErrors] = await safeAsync(
      zoneStore.loadParameters(),
    );
  }

  get logged() {
    return !!currentUserStore.currentUser;
  }
}
</script>

<style lang="scss" scoped></style>
