import { configuratorStore } from "@/store/typed";
import BaseService, { ServiceCode } from "./BaseService";

export default class Service1 extends BaseService {
  zone1Value: number;
  zone2Value: number;
  zone3Value: number;
  readonly code: ServiceCode = "s1";

  get complete() {
    return !!(this.zone1Value || this.zone2Value);
  }

  get total() {
    return this.zone1Value + this.zone2Value + this.zone3Value || 0;
  }

  get maxValue() {
    return this.total * this.percentage;
  }

  get workingDays() {
    return Math.floor(this.maxValue / this.dailyCost);
  }

  get workingFrequency() {
    if (!this.months) {
      return 0;
    }
    return Math.floor(this.workingDays / this.months);
  }

  constructor() {
    super();
    this.percentage = 0.1;
    this.zone1Value = 0;
    this.zone2Value = 0;
    this.zone3Value = 0;
    this.name = "Servizio S1 – Assessment";
  }

  serialize(): Record<string, string | number | boolean> {
    const data = {
      zone1Value: this.zone1Value,
      zone2Value: this.zone2Value,
      zone3Value: this.zone3Value,
      months: this.months,
      total: this.total,
      maxValue: this.maxValue,
      workingDays: this.workingDays,
      workingFrequency: this.workingFrequency,
      complete: this.complete,
    };

    this.labels.forEach((label: { label: string; key: string }) => {
      (data as any)[`${label.key}Label`] = label.label;
    });
    return data;
  }

  get labels(): { label: string; key: string }[] {
    return [
      {
        label: "Valore dei progetti ricadenti nell’ambito del lotto 1",
        key: "zone1Value",
      },
      {
        label: "Valore dei progetti ricadenti nell’ambito del lotto 2",
        key: "zone2Value",
      },
      {
        label: `Valore dei progetti ricadenti nell'ambito del ${configuratorStore.fieldLabel}`,
        key: "zone3Value",
      },
      {
        label: "Mesi di attività del servizio (facoltativo)",
        key: "months",
      },
    ];
  }

  deserialize(data: Record<string, number>): Service1 {
    this.zone1Value = data?.zone1Value || 0;
    this.zone2Value = data?.zone2Value || 0;
    this.zone3Value = data?.zone3Value || 0;
    this.months = data?.months || 0;
    return this;
  }
}
