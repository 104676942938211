<template>
  <svg class="icon">
    <use :xlink:href="svgIcon"></use>
  </svg>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class VSvg extends Vue {
  @Prop() readonly code!: string;

  get svgIcon() {
    return `${require("@/assets/icons/sprite.svg")}#${this.code}`;
  }
}
</script>

<style lang="scss">
@import "@/styles/vue";
s .icon-primary-dark {
  fill: darken($primary, 20);
}
</style>
