<template>
  <page-section>
    <p class="font-weight-bold font-size-20 mb-0 mt-3">Errore di caricamento</p>
    <p>Prova a ricaricare la pagina</p>
    <button class="btn btn-primary" @click="notifyReload">Ricarica</button>
  </page-section>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";

@Component({ components: { PageSection } })
export default class LoadingError extends Vue {
  notifyReload() {
    this.$emit("reload");
  }
}
</script>

<style lang="scss"></style>
