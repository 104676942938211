import Model from "./Model";
import Realm from "./Realm";
import ServiceTool from "./ServiceTool";

export type ServiceKind = "s1" | "s2" | "s3" | "s4";

export class ServicePayload {
  id: string;
  realmId!: string;
  name!: string;
  kind: ServiceKind;
  availableBillingTypes: string[];
  dailyCost!: number;

  constructor(service: Service) {
    this.name = service.name;
    this.id = service.id;
    this.kind = service.kind;
    (this.realmId = service.realm?.id || service.realmId),
      (this.dailyCost = service.dailyCost),
      (this.availableBillingTypes = service.availableBillingTypes);
  }

  static empty(): ServicePayload {
    return {
      id: null,
      realmId: null,
      name: null,
      dailyCost: 0,
      kind: "s1",
      availableBillingTypes: [],
    };
  }
}

export default class Service extends Model {
  static type = "services";

  static billingTypes: Array<{ name: string; id: string }> = [
    { name: "A corpo", id: "fixed_price" },
    { name: "A misura", id: "measured_price" },
  ];
  static kinds: Array<{ name: string; id: string }> = [
    { name: "S1", id: "s1" },
    { name: "S2", id: "s2" },
    { name: "S3", id: "s3" },
    { name: "S4", id: "s4" },
  ];

  name!: any;
  availableBillingTypes!: string[];
  dailyCost!: number;
  realm!: Realm;
  kind: ServiceKind;

  realmId!: string;
  serviceTools!: ServiceTool[];

  static billingTypesByIds(ids: string[]) {
    return Service.billingTypes.filter((o: any) => ids.includes(o.id));
  }

  static empty(): Service {
    const instance = new Service();

    instance.id = null;
    instance.realm = null;
    instance.realmId = null;
    instance.name = null;
    instance.dailyCost = 0;
    instance.availableBillingTypes = [];
    return instance;
  }
}
