<template>
  <transition name="slide-fade">
    <div class="mobile-side-menu" v-if="isVisible">
      <div class="mobile-side-menu--menu text-left" v-click-outside="hide">
        <span class="font-weight-bold" v-if="zone">{{ zone.page_title }}</span>

        <ul class="pl-0 mt-3">
          <div v-for="(link, index) in links" :key="index">
            <div
              class="separation-border my-3"
              v-if="link.separatorBefore"
            ></div>
            <li class="py-2">
              <router-link
                @click.native="goOnSection(link.section)"
                class="nav-link pl-0"
                :class="{ 'pl-0': index == 0 }"
                :to="link.route"
              >
                <span class="font-weight-semibold">{{ link.label }}</span>
              </router-link>
            </li>
          </div>
        </ul>
      </div>

      <div class="mobile-side-menu--close-btn">
        <button class="btn btn-link p-0" @click.stop="hide()">
          <v-svg class="icon" code="it-close" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import NavbarItem from "@/models/ui/NavbarItem";
import ClickOutside from "@monade/vue-components/src/directives/ClickOutside";
import { zoneStore } from "@/store/typed";

import customScroll from "@/initializers/ScrollToUtil";

@Component({
  directives: {
    ClickOutside,
  },
})
export default class MobileSideMenu extends Vue {
  @Prop({ default: [] }) links!: NavbarItem[];

  private visible = false;

  get isVisible(): boolean {
    return this.visible;
  }

  get zone() {
    return zoneStore.zone;
  }

  async show() {
    this.visible = true;
    document.body.classList.add("overflow-hidden");
  }

  hide() {
    document.body.classList.remove("overflow-hidden");
    this.visible = false;
  }

  goOnSection(section: string) {
    customScroll.scroll(section);
    this.hide();
  }
}
</script>

<style lang="scss">
@import "@/styles/vue";

.mobile-side-menu {
  z-index: 9999999;
  position: fixed;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background-image: linear-gradient(rgba(0, 26, 51, 0.5), rgba(0, 26, 51, 0.5));

  &--menu {
    position: absolute;
    left: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    width: 85%;
    height: 100%;
    background-color: white;

    .title {
      font-size: 1.25rem;
    }

    ul {
      list-style-type: none;

      li {
        a {
          font-size: 1.125rem;
        }
      }
    }
  }

  &--close-btn {
    position: absolute;
    right: 0;
    padding-top: 0.625rem;
    padding-right: 0.625rem;

    svg {
      fill: $blue !important;
    }
  }

  .separation-border {
    border-top: 1px solid $gray-300;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
