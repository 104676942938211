import { Component, Mixins } from "vue-property-decorator";
import SimulationMixin from "@/mixins/http/SimulationMixin";
import RequestsMixin from "@/mixins/http/RequestsMixin";
import ServiceMixin from "@/mixins/http/ServiceMixin";

@Component
class SimulationService extends Mixins(
  SimulationMixin,
  RequestsMixin,
  ServiceMixin,
) {}

export default new SimulationService();
