import { ContractDocument, ContractUser, Model, Review, User } from "@/models";
import ContractCompany from "./ContractCompany";
import ContractService from "./ContractService";
import Initiative from "./Initiative";
export default class Contract extends Model {
  static type = "contracts";
  manager!: User;
  managerId!: number;
  status!: string;
  progress!: number;
  startDate!: Date;
  duration!: number;
  endDate!: Date;
  review: Review;
  contractUsers!: ContractUser[];
  contractCompanies!: ContractCompany[];
  contractServices!: ContractService[];
  days: number;
  value: number;
  deliverables: ContractDocument[];
  complianceChecks: ContractDocument[];
  initiative: Initiative;
  expired: boolean;

  get isCompleted() {
    return this.status === "completed";
  }
}
