import { Model } from "@/models";
import { Comment } from "@/models";
import ContractService from "./ContractService";
export enum ContractDocumentType {
  Deliverable = "ContractDocument::Deliverable",
  ComplianceCheck = "ContractDocument::ComplianceCheck",
}

export class ContractDocumentPayload {
  name!: string;
  file!: File;
  description!: string;
  type!: string;
  category!: string;
  contractId!: string;
  comments: Comment[];
  contractServiceId?: string;

  static empty(): ContractDocumentPayload {
    const instance = new ContractDocumentPayload();
    instance.name = null;
    instance.file = null;
    instance.description = null;
    instance.type = null;
    instance.category = null;
    instance.contractId = null;
    instance.contractServiceId = null;
    return instance;
  }
}

export default class ContractDocument extends Model {
  static type = "contractDocuments";

  static categories: Array<{ id: string; label: string }> = [
    { id: "other", label: "Altra tipologia" },
    { id: "program_plans", label: "Piani di programma/progetto" },
    { id: "reporting", label: "Reporting" },
    { id: "sal", label: "SAL" },
    { id: "report", label: "Verbali" },
    { id: "work_estimate", label: "Stima intervento" },
    { id: "tco_estimate", label: "Stima TCO - Total Cost of Ownership" },
    { id: "analysis", label: "Analisi, ricerche e benchmarking" },
  ];

  name!: string;
  fileUrl!: string;
  description!: string;
  documentType!: string;
  category!: string;
  contractId!: string;
  modelType!: string;
  comments!: Comment[];
  contractService?: ContractService;

  get isDeliverable() {
    return this.documentType === "ContractDocument::Deliverable";
  }
}
