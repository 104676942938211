import { Simulation } from "@/models";
import { httpStore } from "@/store/typed";
import { CLOUD_ENABLING_BASE_API_URL } from "@/utils/Constants";
import { Component, Vue } from "vue-property-decorator";
export enum SimulationTags {
  SimulationShow = "SimulationShow",
  SimulationClose = "SimulationClose",
  SimulationUpdateMetadata = "SimulationUpdateMetadata",
  SimulationSendEmail = "SimulationSendEmail",
}

@Component
export default class SimulationMixin extends Vue {
  readonly path = "/simulations";
  readonly routes = this.$getModelRoutes(this.path);

  getSimulation(): Promise<Simulation> {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/simulations/current`;
    return httpStore.request({
      tag: SimulationTags.SimulationShow,
      url: url,
      method: "GET",
    }) as Promise<Simulation>;
  }

  updateSimulation(data: any) {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/simulations/update_metadata`;
    return httpStore.request({
      tag: SimulationTags.SimulationUpdateMetadata,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<Simulation>;
  }

  closeSimulation() {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/simulations/close`;
    return httpStore.request({
      tag: SimulationTags.SimulationClose,
      url: url,
      method: "POST",
    }) as Promise<Simulation>;
  }

  sendSimulationMail() {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/simulations/send_email`;
    return httpStore.request({
      tag: SimulationTags.SimulationSendEmail,
      url: url,
      method: "POST",
    }) as Promise<Simulation>;
  }
}
