import Media, { MediaSize } from "@/models/strapi/Media";
import { STRAPI_MEDIA_BASE_API_URL } from "./Constants";

export function urlFromMedia(media: Media, size: MediaSize = "thumbnail") {
  const path =
    media.formats && media.formats[size] ? media.formats[size].url : media.url;
  if (path.includes("eu-central-1") || path.includes("googleapis")) {
    return path;
  }
  const url = `${STRAPI_MEDIA_BASE_API_URL}${path}`;
  return url;
}
