<template>
  <div class="it-header-navbar-wrapper bg-dark">
    <div class="container px-0">
      <div class="row px-0">
        <div class="col-12 px-0">
          <nav class="navbar navbar-expand-sm has-megamenu">
            <div class="navbar-collapsable" id="nav02">
              <div class="menu-wrapper">
                <ul class="navbar-nav"></ul>
                <div class="it-right-zone">
                  <ul class="navbar-nav">
                    <li
                      class="nav-item"
                      v-for="(link, index) in links"
                      :key="index"
                    >
                      <router-link class="nav-link pr-0" :to="link.route"
                        ><span>{{ link.label }}</span>
                      </router-link>
                    </li>

                    <li
                      class="position-relative nav-item d-flex left-separator ml-3 right-separator px-3"
                    >
                      <v-svg
                        code="it-user"
                        class="user-icon icon-white my-auto icon-sm mr-2"
                        data-toggle="dropdown"
                      />
                      <user-navbar-dropdown v-if="logged" />
                      <router-link
                        v-else
                        class="nav-link font-weight-bold my-auto px-0"
                        :to="{ name: 'login' }"
                        ><span>Accedi all'area riservata</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import UserNavbarDropdown from "@/components/Common/UserNavbarDropdown.vue";
import { currentUserStore } from "@/store/typed";
import NavbarItem from "../../models/ui/NavbarItem";

@Component({ components: { UserNavbarDropdown } })
export default class SlimNavbar extends Vue {
  @Prop() readonly links!: NavbarItem[];

  get logged() {
    return currentUserStore.currentUser;
  }
}
</script>

<style lang="scss" scoped>
a.nav-link {
  font-size: 14px !important;
  &.router-link-exact-active {
    text-decoration: underline;
  }
}
.user-icon {
  cursor: pointer;
}
</style>
