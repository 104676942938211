import WatcherAreaData from "@/models/WatcherAreaData";
import { safeAsync } from "@/utils/AsyncUtil";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import IndicatorService from "@/service/IndicatorService";
import { json2xml } from "xml-js";
import MonitoringAreaData from "@/models/MonitoringAreaData";

export type MonitoringFilter = {
  organizationRegion?: string;
  organizationType?: string;
  organizationCategory?: string;
  initiativeStep?: string;
};

@Module({
  stateFactory: true,
  namespaced: true,
  name: "monitoring",
})
export default class Monitoring extends VuexModule {
  _filter: MonitoringFilter = {};

  private _data = new MonitoringAreaData();

  get filter(): MonitoringFilter {
    return this._filter;
  }
  get organizationRegion(): string {
    return this._filter.organizationRegion;
  }
  get organizationType(): string {
    return this._filter.organizationType;
  }
  get organizationCategory(): string {
    return this._filter.organizationCategory;
  }
  get initiativeStep(): string {
    return this._filter.initiativeStep;
  }
  get data(): MonitoringAreaData {
    return this._data;
  }

  @Mutation
  _reset() {
    this._filter = {};
  }

  @Action
  reset() {
    const { commit } = this.context;
    commit("_reset");
  }

  @Mutation
  _setFilter(payload: MonitoringFilter) {
    this._filter = { ...this.filter, ...payload };
  }

  @Mutation
  _setData(payload: { value: any; key: string }) {
    (this._data as any)[payload.key] = payload.value;
  }

  @Action
  setFilter(payload: MonitoringFilter) {
    const { commit } = this.context;
    commit("_setFilter", payload);
  }

  @Action
  exportData() {
    const element = document.createElement("a");

    element.setAttribute(
      "href",
      "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.data)),
    );
    element.setAttribute("download", "export.json");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  @Action
  exportXmlData() {
    const element = document.createElement("a");

    const jsObject = { data: this.data };

    const jsonString = JSON.stringify(jsObject);
    const xmlFile = json2xml(jsonString, {
      compact: true,
      spaces: 4,
      ignoreDeclaration: false,
    });

    element.setAttribute(
      "href",
      "data:text/xml;charset=utf-8," + encodeURIComponent(xmlFile),
    );
    element.setAttribute("download", "export.xml");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  @Action
  async getExecutiveContractsTrend() {
    const { commit } = this.context;

    const reqeustPayload = {
      filter: {
        ...this.filter,
      },
    };

    const [data, errors] = await safeAsync(
      IndicatorService.getExecutiveContractsTrend(reqeustPayload),
    );
    if (data) {
      commit("_setData", { value: data, key: "executiveContractsTrend" });
      return data;
    } else {
      commit("_setData", { value: null, key: "executiveContractsTrend" });
      return null;
    }
  }

  @Action
  async getMonitoringSupplyData(payload: { code: string; category: string }) {
    const { commit } = this.context;

    const reqeustPayload = {
      filter: {
        code: payload.code,
        category: payload.category,
        ...this.filter,
      },
    };

    const [data, errors] = await safeAsync(
      IndicatorService.getMonitoringSupply(reqeustPayload),
    );
    if (data) {
      commit("_setData", { value: data, key: "monitoringSupplyIndicators" });
      return data;
    } else {
      commit("_setData", { value: null, key: "monitoringSupplyIndicators" });
      return null;
    }
  }

  @Action
  async getReviewIndicators() {
    const { commit } = this.context;

    const reqeustPayload = {
      filter: {
        ...this.filter,
      },
    };

    const [data, errors] = await safeAsync(
      IndicatorService.getReviewIndicators(reqeustPayload),
    );
    if (data) {
      commit("_setData", { value: data.indicators, key: "reviewIndicators" });
      return data.indicators;
    } else {
      commit("_setData", { value: null, key: "reviewIndicators" });
      return null;
    }
  }

  @Action
  async getQualitativeIndicators() {
    const { commit } = this.context;

    const reqeustPayload = {
      filter: {
        ...this.filter,
      },
    };

    const [data, errors] = await safeAsync(
      IndicatorService.getMonitoringQualitativeIndicators(reqeustPayload),
    );
    if (data) {
      commit("_setData", {
        value: data.indicators,
        key: "qualitativeIndicators",
      });
      console.log("data", data);
      return data.indicators;
    } else {
      commit("_setData", { value: null, key: "qualitativeIndicators" });
      return null;
    }
  }
}
