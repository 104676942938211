<template>
  <span
    class="icon"
    :class="[{ spin: spin }, sizeClass]"
    v-html="svgContent"
  ></span>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-var-requires */
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ name: "icon" })
export default class VIcon extends Vue {
  @Prop({ default: false }) spin?: boolean;

  svgContent: string | null = null;

  created() {
    this.getSvg();
  }

  getIcon() {
    return this.$slots.default && this.$slots.default.length
      ? this.$slots.default[0].text
      : null;
  }

  getSvg(): void {
    const icon = this.getIcon();
    console.log('Icon:', icon); // Log the icon name
    if (!icon) return null;

    import(`../assets/icons/${icon}.svg?raw`).then((svg) => {
      this.svgContent = svg.default;
    });
  }

  @Prop({ default: "md" })
  readonly size!: "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl";

  get sizeClass() {
    return `icon-${this.size}`;
  }
}
</script>

<style lang="scss">
.icon {
  color: inherit;
  display: inline-flex;

  > svg {
    font-size: inherit;
    margin: auto;

    display: block;

    * {
      opacity: 1;
    }

    path,
    rect {
      fill: currentColor;
      stroke: none;
    }

    line {
      stroke: currentColor;
    }
  }

  &.spin {
    > svg {
      animation-name: spin;
      animation-duration: 750ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
