import BaseService, { ServiceCode } from "./BaseService";

export default class Service3 extends BaseService {
  readonly code: ServiceCode = "s3";

  value: number;

  get complete() {
    return !!this.value;
  }

  get total() {
    return this.value;
  }

  get maxValue() {
    return this.value;
  }

  get workingDays() {
    return Math.floor(this.maxValue / this.dailyCost);
  }

  get workingFrequency() {
    if (!this.months) {
      return 0;
    }
    return Math.floor(this.workingDays / this.months);
  }

  constructor() {
    super();
    this.disabled = true;
    this.name = "Servizio S3 – Studio di fattibilità";
    this.tooltip =
      "Per poter simulare il servizio S3 è necessario configurare uno dei due servizi precedenti";
  }

  serialize(): Record<string, string | number | boolean> {
    const data = {
      value: this.value,
      months: this.months,
      total: this.total,
      maxValue: this.maxValue,
      workingDays: this.workingDays,
      complete: this.complete,
      workingFrequency: this.workingFrequency,
    };
    this.labels.forEach((label: { label: string; key: string }) => {
      (data as any)[`${label.key}Label`] = label.label;
    });
    return data;
  }

  get labels(): { label: string; key: string }[] {
    return [
      {
        label: "Importo previsto per il servizio S3",
        key: "value",
      },
      {
        label: "Mesi di attività di servizio",
        key: "months",
      },
    ];
  }

  deserialize(data: Record<string, number>): Service3 {
    this.months = data?.months || 0;
    this.value = data?.value || 0;
    return this;
  }
}
