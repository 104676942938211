import Media from "./Media";
import Model from "../Model";
import ServiceBox from "./ServiceBox";
import TeamMember from "./TeamMember";
import Tool from "../Tool";

export default class Service extends Model {
  name!: string;
  title!: Date;
  description!: string;
  cover!: Media;
  summary!: string;
  why_title!: string;
  why_text!: string;
  why_image!: Media;
  content_title!: string;
  content_text!: string;
  team_title!: string;
  team_text!: string;
  solutions_title!: string;
  solutions_subtitle!: string;
  solutions_text!: string;
  service_boxes!: ServiceBox[];
  team_members!: TeamMember[];
  tools!: Tool[];
}
