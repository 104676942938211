import {
  IndicatorFilter,
  IndicatorTaxonomy,
  IndicatorDistribution,
  IndicatorQualitative,
  Review,
} from "@/models";
import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { CLOUD_ENABLING_BASE_API_URL } from "@/utils/Constants";
import qs from "qs";
import DigitalSpecificIndicator from "@/models/DigitalSpecificIndicator";
import CollaborationIndicatorReport from "@/models/CollaborationIndicatorReport";
import ReviewIndicator from "@/models/ReviewIndicator";
import SupplierQualityIndicator from "@/models/SupplierQualityIndicator";

export enum IndicatorMixinTags {
  IndicatorFilters = "IndicatorFilters",
  IndicatorTaxonomy = "IndicatorTaxonomy",
  IndicatorDistribution = "IndicatorDistribution",
  DigitalSpecific = "DigitalSpecific",
  CollaborationReuse = "CollaborationReuse",
  IndicatorQualitative = "IndicatorQualitative",
  IndicatorQuantitative = "IndicatorQuantitative",
  IndicatorMonitoringSupply = "IndicatorMonitoringSupply",
  Review = "Review",
  MonitoringQualitative = "MonitoringQualitative",
  IndicatorExecutiveContractsTrend = "IndicatorExecutiveContractsTrend",
}

@Component
export default class IndicatorMixin extends Vue {
  getFilters() {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/indicators/filters`;
    return httpStore.request({
      tag: IndicatorMixinTags.IndicatorFilters,
      url: url,
      method: "GET",
    }) as Promise<IndicatorFilter>;
  }

  getTaxonomy(data: any) {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/indicators/taxonomy`;
    return httpStore.request({
      tag: IndicatorMixinTags.IndicatorTaxonomy,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }) as Promise<IndicatorTaxonomy>;
  }

  getDistribution(data: any) {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/indicators/distribution`;
    return httpStore.request({
      tag: IndicatorMixinTags.IndicatorTaxonomy,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }) as Promise<IndicatorDistribution>;
  }

  getQualitative(data: any) {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/indicators/qualitative`;
    return httpStore.request({
      tag: IndicatorMixinTags.IndicatorQualitative,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }) as Promise<IndicatorQualitative>;
  }

  getMonitoringSupply(data: any) {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/indicators/monitoring_supply`;
    return httpStore.request({
      tag: IndicatorMixinTags.IndicatorMonitoringSupply,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }) as Promise<IndicatorDistribution>;
  }

  getExecutiveContractsTrend(data: any) {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/indicators/executive_contracts_trend`;
    return httpStore.request({
      tag: IndicatorMixinTags.IndicatorExecutiveContractsTrend,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }) as Promise<IndicatorDistribution>;
  }

  getQuantitative(data: any) {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/indicators/quantitative`;
    return httpStore.request({
      tag: IndicatorMixinTags.IndicatorQuantitative,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }) as Promise<IndicatorDistribution>;
  }

  getDigitalSpecificIndicators(data: any) {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/indicators/digital_specific`;

    return httpStore.request({
      tag: IndicatorMixinTags.DigitalSpecific,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }) as Promise<DigitalSpecificIndicator[]>;
  }

  getCollaborationReuseIndicators(data: any) {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/indicators/collaboration_reuse`;

    return httpStore.request({
      tag: IndicatorMixinTags.CollaborationReuse,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }) as Promise<CollaborationIndicatorReport[]>;
  }

  getReviewIndicators(data: any) {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/indicators/review`;

    return httpStore.request({
      tag: IndicatorMixinTags.Review,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }) as Promise<ReviewIndicator>;
  }

  getMonitoringQualitativeIndicators(data: any) {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/indicators/monitoring_qualitative`;

    return httpStore.request({
      tag: IndicatorMixinTags.MonitoringQualitative,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }) as Promise<any>;
  }
}
