import { Component, Vue } from "vue-property-decorator";
import { CLOUD_ENABLING_BASE_API_URL } from "../../utils/Constants";
import qs from "qs";
import { httpStore } from "@/store/typed";

@Component
export default class RequestsMixin extends Vue {
  $getRoute(path: string) {
    return CLOUD_ENABLING_BASE_API_URL + path;
  }

  $getModelRoutes(path: string) {
    path = CLOUD_ENABLING_BASE_API_URL + path;
    const data = {
      getUrl: (
        id: string,
        opts?: { fields?: { [key: string]: string }; include?: string },
      ) => {
        const params = qs.stringify(
          { fields: opts?.fields, include: opts?.include },
          { arrayFormat: "brackets" },
        );
        const query = params.length > 0 ? "?" + params : "";

        return path + "/" + id + query;
      },
      createUrl: () => path,
      indexUrl: (params: any) =>
        path + "?" + qs.stringify(params, { arrayFormat: "brackets" }),
      updateUrl: (id: string) => path + "/" + id,
      deleteUrl: (id: string) => path + "/" + id,
    };

    return data;
  }

  $httpGet(url: string, tag: string) {
    return httpStore.request({
      tag: tag,
      url: url,
      method: "GET",
    });
  }

  public $httpPost(url: string, tag: string, data: string) {
    return httpStore.request({
      tag: tag,
      url: url,
      data: data,
      method: "POST",
    });
  }

  public $httpPut(url: string, tag: string, data: any) {
    return httpStore.request({
      tag: tag,
      url: url,
      data: data,
      method: "PUT",
    });
  }

  public $httpDelete(url: string, tag: string, data: any = null) {
    return httpStore.request({
      tag: tag,
      url: url,
      data: data,
      method: "DELETE",
    });
  }
}
