<template>
  <div class="it-header-navbar-wrapper primary-bg-a12">
    <div class="container px-0">
      <div class="row">
        <div class="col-12 px-0">
          <nav class="navbar navbar-expand-lg has-megamenu">
            <div class="navbar-collapsable" id="nav02" style="display: block">
              <div class="close-div sr-only">
                <button class="btn close-menu" type="button">
                  <span class="it-close"></span>close
                </button>
              </div>
              <div class="menu-wrapper">
                <ul
                  class="navbar-nav"
                  :class="{ 'carded shadow': isLogged && !isHome }"
                >
                  <template v-for="(link, index) in leftLinks">
                    <li
                      v-if="!link.hidden"
                      :key="index"
                      :class="{
                        'left-separator': index !== 0 && isLogged && !isHome,
                      }"
                      class="nav-item"
                    >
                      <router-link
                        @click.native="goOnSection(link.section)"
                        class="nav-link"
                        :class="{
                          'pl-0': index == 0 && (!isLogged || isHome),
                          'router-link-exact-active': routeActive(link),
                        }"
                        :to="link.route"
                        ><span>{{ link.label }}</span>
                      </router-link>
                    </li>
                  </template>
                </ul>
                <div class="it-right-zone">
                  <ul class="navbar-nav">
                    <li
                      class="nav-item"
                      v-for="(link, index) in rightLinks"
                      :key="index"
                    >
                      <router-link class="nav-link pr-0" :to="link.route"
                        ><v-icon
                          v-if="link.icon"
                          size="xs"
                          class="text-white mr-1"
                          >{{ link.icon }}</v-icon
                        ><span>{{ link.label }}</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import NavbarItem from "../../models/ui/NavbarItem";
import { currentUserStore } from "@/store/typed";
import customScroller from "@/initializers/ScrollToUtil";

@Component({})
export default class StickyNavbar extends Vue {
  @Prop() readonly rightLinks!: NavbarItem[];
  @Prop() readonly leftLinks!: NavbarItem[];

  get currentUser() {
    return currentUserStore.currentUser;
  }
  get isLogged() {
    return !!this.currentUser;
  }

  get isHome() {
    return this.$isRouteActive("home");
  }

  get isAdministrative() {
    return !this.currentUser?.isOneOf(["admin", "superadmin"]);
  }

  goOnSection(section: string) {
    customScroller.scroll(section);
  }

  routeActive(item: NavbarItem) {
    return (
      item.route === this.$route.name ||
      item.activeRoutes?.some((r: string) => r === this.$route.name)
    );
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/vue";

a.nav-link {
  font-size: 14px !important;
}

.right-separator {
  border-color: #5c6f8229 !important;
}

.carded {
  z-index: $zindex-dropdown;
  margin-bottom: -16px;
  border-radius: 5px;
  background: white;

  .nav-item {
    .nav-link {
      color: $primary-a12 !important;
      font-size: 16px !important;

      &.router-link-exact-active {
        font-weight: bold !important;
        border-bottom: 3px solid $primary !important;
      }
    }
  }
}
</style>
