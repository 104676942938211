import Service1 from "@/models/configurator/Service1";
import Service2 from "@/models/configurator/Service2";
import Service3 from "@/models/configurator/Service3";
import simulationService from "@/service/SimulationService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import Simulation from "@/models/Simulation";
import Throttler from "@/utils/Throttler";
import Service4 from "@/models/configurator/Service4";

@Module({
  stateFactory: true,
  namespaced: false,
  name: "configurator",
})
export default class Configurator extends VuexModule {
  private _simulation: Simulation | undefined = undefined;
  private _service1: Service1 | undefined = undefined;
  private _service2: Service2 | undefined = undefined;
  private _service3: Service3 | undefined = undefined;
  private _service4: Service4 | undefined = undefined;
  private _fieldLabel: string | undefined = undefined;
  private throttler: Throttler = new Throttler();

  get services(): (Service1 | Service3 | Service2 | Service4)[] {
    return [this.service1, this.service2, this.service3, this.service4];
  }

  get service1(): Service1 {
    return Object.assign(new Service1(), this._service1);
  }

  get service2(): Service2 {
    return Object.assign(new Service2(), this._service2);
  }

  get service3(): Service3 {
    return Object.assign(new Service3(), this._service3);
  }

  get service4(): Service4 {
    return Object.assign(new Service4(), this._service4);
  }

  get simulation(): Simulation {
    return Object.assign(new Simulation(), this._simulation);
  }

  get fieldLabel(): string {
    return this._fieldLabel;
  }

  get alreadyInitialized() {
    return this.service1 || this.service2 || this.service3 || this.service4;
  }

  @Mutation
  setFieldLabel(payload: string | undefined) {
    this._fieldLabel = payload;
  }

  @Mutation
  setService1(payload: Service1 | undefined) {
    this._service1 = payload;
  }

  @Mutation
  setService2(payload: Service2 | undefined) {
    this._service2 = payload;
  }

  @Mutation
  setService3(payload: Service3 | undefined) {
    this._service3 = payload;
  }

  @Mutation
  setService4(payload: Service4 | undefined) {
    this._service4 = payload;
  }

  @Mutation
  setSimulation(payload: Simulation | undefined) {
    this._simulation = payload;
  }

  @Mutation
  checkService3Accessibility() {
    this._service3.disabled = !(
      this._service1.complete && this._service2.complete
    );
  }

  @Action({ rawError: true })
  async updateService1(service: Service1) {
    const { commit, dispatch } = this.context;
    commit("setService1", service);
    dispatch("updateSimulation", { service1: service.serialize() });
    commit("checkService3Accessibility", service);
  }

  @Action({ rawError: true })
  async updateService2(service: Service2) {
    const { commit, dispatch } = this.context;
    commit("setService2", service);
    dispatch("updateSimulation", { service2: service.serialize() });
    commit("checkService3Accessibility", service);
  }

  @Action({ rawError: true })
  async updateService3(service: Service3) {
    const { commit, dispatch } = this.context;
    commit("setService3", service);
    dispatch("updateSimulation", { service3: service.serialize() });
  }

  @Action({ rawError: true })
  async updateService4(service: Service4) {
    const { commit, dispatch } = this.context;
    commit("setService4", service);
    dispatch("updateSimulation", { service4: service.serialize() });
  }

  @Action({ rawError: true })
  async updateSimulation(payload: any) {
    if (!this.simulation) {
      return;
    }
    const data = { metadata: Object.assign(this.simulation.metadata, payload) };
    if (!data.metadata.service1) {
      data.metadata.service1 = {};
    }

    if (!data.metadata.service2) {
      data.metadata.service2 = {};
    }

    if (!data.metadata.service3) {
      data.metadata.service3 = {};
    }

    const simulation = await simulationService.updateSimulation(data);
    this.setSimulation(simulation);
  }
}
