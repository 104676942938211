<template>
  <div class="d-flex justify-content-center" v-if="show">
    <p class="my-auto text-dark font-size-14 ml-0">Seguici su:</p>
    <div class="my-auto" v-for="(link, index) of socialLinks" :key="index">
      <a :href="link.url" target="_blank">
        <v-svg class="icon icon-sm ml-3 icon-dark" :code="link.name" />
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import SocialLink from "@/models/strapi/SocialLink";
import { zoneStore } from "@/store/typed";

@Component({})
export default class SocialLinks extends Vue {
  get socialLinks(): SocialLink[] | undefined {
    return zoneStore.zone?.social_icons;
  }

  get show() {
    return this.socialLinks?.length;
  }

  svgIcon(id: string) {
    return `${require("@/assets/icons/sprite.svg")}#${id}`;
  }
}
</script>

<style lang="scss" scoped>
.icon {
  fill: white;
}
</style>
