import { Model } from "@/models";
import QuantitativeIndicatorReport from "@/models/QuantitativeIndicatorReport";
import { groupBy } from "@/utils/Chunker";
import { REALM_REGIONS } from "@/utils/Constants";

export class IndicatorQuantitativeMetadata {
  budget: { spent: number; saved: number };
  expense: { before: number; after: number };
  projects: { completed: number; inProgress: number };
}

export default class IndicatorQuantitative extends Model {
  static type = "indicatorQuantitatives";
  realmCode: string;
  realmName: string;
  values: Array<QuantitativeEntry>;
  region: Array<string>;

  static fromData(indicators: IndicatorQuantitative) {
    const instance = new QuantitativeIndicatorReport();
    instance.realmName = indicators.realmName;
    instance.data = this.aggregateValues(indicators);
    return instance;
  }

  static sumValues(array: any[], context: string, key: string) {
    return array.reduce((a: any, b: any) => {
      return b.metadata[context][key] + a;
    }, 0);
  }

  static emptyRegion(name: string) {
    return {
      region: name,
      budget: {
        spent: 0,
        saved: 0,
      },
      expense: {
        before: 0,
        after: 0,
      },
      projects: {
        completed: 0,
        inProgress: 0,
      },
    };
  }

  static aggregateValues(indicators: IndicatorQuantitative): any {
    const regionGroup = groupBy(indicators.values, "organization_region");

    const results: Array<QuantitativeEntry> = [];
    Object.keys(regionGroup).forEach((k: string) => {
      const item = {
        region: k,
        budget: {
          spent: this.sumValues(regionGroup[k], "budget", "spent"),
          saved: this.sumValues(regionGroup[k], "budget", "saved"),
        },
        expense: {
          before: this.sumValues(regionGroup[k], "expense", "before"),
          after: this.sumValues(regionGroup[k], "expense", "after"),
        },
        projects: {
          completed: this.sumValues(regionGroup[k], "projects", "completed"),
          inProgress: this.sumValues(regionGroup[k], "projects", "in_progress"),
        },
      };

      results.push(item);
    });

    const regions = REALM_REGIONS[indicators.realmCode].filter(
      (o: string) => !results.map((i: any) => i.region).includes(o),
    );
    regions.forEach((v: string) => {
      results.push(this.emptyRegion(v));
    });

    return results;
  }
}

export class QuantitativeData {
  values: Array<QuantitativeEntry>;
}
export type QuantitativeEntry = {
  region: string;
  budget: { spent: number; saved: number };
  expense: { before: number; after: number };
  projects: { completed: number; inProgress: number };
};
