<template>
  <div class="w-100 bg-dark it-header-slim-wrapper py-0">
    <div class="container text-left d-flex px-0">
      <div
        class="nav-item dropdown"
        @click.stop="setShowLinks(!showLinks)"
        v-click-outside="closeLinks"
      >
        <p
          class="nav-link dropdown-toggle px-0 mb-0 text-white"
          href="#"
          :aria-expanded="open.toString()"
          @click.stop="toggleOpen"
          v-click-outside="closeDropdown"
        >
          <span
            >Link <v-svg class="icon-sm icon-white" code="it-expand"
          /></span>
        </p>
        <div
          class="dropdown-menu"
          :class="[{ 'right-caret': true }, { show: open }]"
        >
          <div class="row">
            <div class="col-12 px-0">
              <div class="link-list-wrapper">
                <ul class="link-list">
                  <li v-for="(link, index) of links" :key="index">
                    <router-link :to="link.route" class="list-item" href="#"
                      ><span>{{ link.label }}</span></router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="left-separator ml-auto d-flex px-2">
        <user-navbar-dropdown :isMobile="true" v-if="logged" />
        <router-link
          v-else
          class="nav-link font-weight-bold my-auto px-0 white-color pl-2"
          :to="{ name: 'login' }"
          ><span>Accedi all'area riservata</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import NavbarItem from "../../models/ui/NavbarItem";
import UserNavbarDropdown from "@/components/Common/UserNavbarDropdown.vue";
import { currentUserStore } from "@/store/typed";
import ClickOutside from "@/directives/ClickOutside";

@Component({
  components: {
    UserNavbarDropdown,
  },
  directives: {
    ClickOutside,
  },
})
export default class MobileSlimNavbar extends Vue {
  @Prop() readonly links!: NavbarItem[];

  private open = false;

  toggleOpen() {
    this.open = !this.open;
  }

  closeDropdown() {
    this.open = false;
  }

  private showLinks = false;

  get logged() {
    return currentUserStore.currentUser;
  }

  private setShowLinks(value: boolean) {
    this.showLinks = value;
  }

  private closeLinks() {
    this.showLinks = false;
  }
}
</script>

<style lang="scss"></style>
