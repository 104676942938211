<template>
  <div
    class="progress-spinner progress-spinner-double progress-spinner-active"
    :class="[sizeClass]"
  >
    <div class="progress-spinner-inner"></div>
    <div class="progress-spinner-inner"></div>
    <span class="sr-only">Caricamento...</span>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class Spinner extends Vue {
  @Prop({ default: "sm" }) size!: string;

  get sizeClass() {
    return `size-${this.size}`;
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/vue";

.progress-spinner {
  &.size-xs {
    width: $v-gap * 2;
    height: $v-gap * 2;
  }

  &.progress-spinner-double {
    &.size-xs {
      width: $v-gap * 2;
      height: $v-gap * 2;
      //double spinner inner
      .progress-spinner-inner {
        width: $v-gap * 2;
        height: $v-gap * 2;
      }
    }
  }
}
</style>
