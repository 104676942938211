/* eslint-disable @typescript-eslint/no-explicit-any */
export interface JSONModel {
  id: string;
  type: string;
  attributes?: { [key: string]: any };
  relationships?: { [key: string]: any };
}

export interface JSONData {
  data: JSONModel | JSONModel[];
  included: JSONModel[];
}

export class Parser {
  static parse<T extends Model>(
    data: JSONData | JSONModel[] | JSONModel,
    included: JSONModel[] = [],
  ): T | T[] | null {
    if (data == null) {
      return null;
    }
    if (Array.isArray(data)) {
      return Parser.parseList(data, included) as T[];
    } else if ("data" in data && !("id" in data)) {
      return Parser.parse(data.data, data.included || included);
    } else {
      return Parser.parseElement(data as JSONModel, included) as T;
    }
  }

  static parseList(list: JSONModel[], included: JSONModel[]) {
    return list.map((e) => {
      return Parser.parseElement(e, included);
    });
  }

  static parseElement(element: JSONModel, included: JSONModel[]): Model {
    // eslint-disable-next-line
    const models = require(".");

    element = Parser.load(element, included);
    const model = Object.values(models).find(
      (e: any) => e.type === element.type,
    ) as any;
    // eslint-disable-next-line
    return new (model || Model)(element, included);
  }

  static load(element: JSONModel, included: JSONModel[]) {
    // eslint-disable-next-line
    return (
      included.find((e) => e.id == element.id && e.type === element.type) ||
      element
    );
  }
}

export default class Model {
  id!: string;
  static type: string | undefined;

  constructor(data?: JSONModel | null | any, included: JSONModel[] = []) {
    if (!data) {
      return;
    }
    this.id = data.id;
    for (const key in data.attributes) {
      (this as any)[key] = data.attributes[key];
    }
    for (const key in data.relationships) {
      const relation = data.relationships[key];
      if (relation) {
        (this as any)[key] = Parser.parse(relation, included);
      }
    }
  }

  toJSON(): any {
    return { ...this };
  }

  toFormData() {
    const data = this.toJSON();
    const formData = new FormData();
    for (const key in data) {
      if (data[key] !== null && data[key] !== undefined) {
        if (Array.isArray(data[key])) {
          for (const value of data[key]) {
            formData.append(key + "[]", value);
          }
        } else if (data[key] instanceof File) {
          formData.append(key, data[key], data[key].filename);
        } else {
          formData.append(key, data[key]);
        }
      }
    }
    return formData;
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */
