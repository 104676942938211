import Model from "./Model";
import Service from "./Service";
import ServiceTool from "./ServiceTool";

export type ServiceToolsAttributes = {
  id?: string;
  toolId?: string;
  serviceId?: string;
  _destroy?: boolean;
};

export class ToolPayload {
  id: string;
  name: string;
  url: string;
  description: string;
  slug: string;
  serviceToolsAttributes: ServiceToolsAttributes[];
  serviceTools?: ServiceTool[];

  constructor(tool?: Tool) {
    this.name = tool?.name;
    this.id = tool?.id;
    this.url = tool?.url;
    this.slug = tool?.slug;
    this.description = tool?.description;
    this.serviceToolsAttributes = tool?.serviceTools?.map((serviceTool) => ({
      id: serviceTool.id,
      serviceId: serviceTool.serviceId,
      toolId: serviceTool.toolId,
    }));
    this.serviceTools = tool?.serviceTools;
  }

  static clone(toolPayload: ToolPayload): ToolPayload {
    const newToolPayload = new ToolPayload();
    newToolPayload.id = toolPayload.id;
    newToolPayload.name = toolPayload.name;
    newToolPayload.url = toolPayload.url;
    newToolPayload.description = toolPayload.description;
    newToolPayload.slug = toolPayload.slug;
    newToolPayload.serviceToolsAttributes = [
      ...toolPayload.serviceToolsAttributes,
    ];

    return newToolPayload;
  }

  static empty(): ToolPayload {
    const emptyToolPayload = new ToolPayload();
    emptyToolPayload.id = null;
    emptyToolPayload.name = null;
    emptyToolPayload.url = null;
    emptyToolPayload.description = null;
    emptyToolPayload.slug = null;
    emptyToolPayload.serviceToolsAttributes = [];

    return emptyToolPayload;
  }
}

export default class Tool extends Model {
  static type = "tools";

  name!: string;
  url!: string;
  description!: string;
  slug!: string;
  services!: Service[];
  serviceTools!: ServiceTool[];

  static empty(): Tool {
    const instance = new Tool();

    instance.id = null;
    instance.name = null;
    instance.description = null;
    instance.slug = null;
    instance.url = null;
    return instance;
  }
}
