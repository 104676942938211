export const STRAPI_BASE_API_URL: string =
  process.env.STRAPI_BASE_API_URL ??
  "https://cloud-enabling-gateway.monadeapps.xyz/content";

export const STRAPI_MEDIA_BASE_API_URL: string =
  process.env.STRAPI_MEDIA_BASE_API_URL ??
  "https://cloud-enabling-gateway.monadeapps.xyz/content";

export const CLOUD_ENABLING_BASE_API_URL: string =
  process.env.CLOUD_ENABLING_BASE_API_URL ??
  "http://cloud-enabling-api.test/v1";

export const PPD_BASE_ADMIN_API_URL: string =
  `${CLOUD_ENABLING_BASE_API_URL}/admin` ??
  "http://cloud-enabling-api.test/v1/admin";

export const FRONTEND_URL: string =
  process.env.FRONTEND_URL ?? "http://cloud-enabling.test";

export const DEFAULT_LOGGED_ROUTE = "secure/orders?page=1";

export const IPA_BASE_API_URL = "https://indicepa.gov.it/PortaleServices/api/";

export enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

//TODO: PAC and PAL realms are all about center, so maybe this should be all center
export const REALM_REGIONS: any = {
  pal: ["Lazio", "Marche", "Toscana", "Umbria"],
  pac: ["Lazio", "Marche", "Toscana", "Umbria"],
};

export const CHART_PALETTE: string[] = [
  "#D0E2F5",
  "#A6CAED",
  "#7FB2E5",
  "#599BDE",
  "#3686D6",
  "#0059B3",
  "#004D99",
  "#004080",
  "#003366",
  "#00264D",
  "#001A33",
];

export const CHART_PRIMARY_COLOR = "#0059B3";
export const CHART_SECONDARY_COLOR = "#5C6F82";

export const INDICATOR_DESCRIPTIONS: Record<
  string,
  {
    short: string;
    long: string;
    familyDescription?: string;
    publicTender?: string;
  }
> = {
  "IG-1": {
    short: "",
    long: "Riduzione % spesa per l’erogazione del servizio",
  },
  "IG-2": { short: "", long: "Riduzione % tempi di erogazione del servizio" },
  "IG-3": { short: "", long: "Numero servizi aggiuntivi offerti" },
  "IG-4": { short: "", long: "Obiettivi CAD raggiunti con l’intervento" },
  "IG-4-1": {
    short: "Digitalizzazione",
    long: "Digitalizzazione e dematerializzazione delle attività amministrativa con semplificazione dei procedimenti amministrativi",
  },
  "IG-4-2": {
    short: "Standard di qualità",
    long: "Innalzamento degli standard qualitativi dei servizi erogati tramite ricorso a soluzioni digitali, in termini di efficacia, efficienza ed economicità",
  },
  "IG-4-3": {
    short: "Accessibilità",
    long: "Maggiore accessibilità digitale ai servizi pubblici nel rispetto dei principi di uguaglianza, imparzialità e di non discriminazione",
  },
  "IG-4-4": {
    short: "Misurabilità",
    long: "Misurabilità delle perfomance delle Amministrazione nell’erogazione dei servizi",
  },
  "IG-4-5": {
    short: "Performance",
    long: "Analisi delle performance e individuazione delle aree di miglioramento",
  },
  "IG-4-6": {
    short: "Trasparenza",
    long: "Incremento della trasparenza in merito alle condizioni di accesso e utilizzo dei servizi digitali",
  },

  "IG-5-1": { short: "CIE", long: "IG-5-1 - CIE" },
  "IG-5-2": { short: "SPID", long: "IG-5-2 - SPID" },
  "IG-5-3": { short: "PagoPA", long: "IG-5-3 - PagoPA" },
  "IG-5-4": { short: "FatturaPA", long: "IG-5-4 - FatturaPA" },
  "IG-5-5": { short: "ANPR", long: "IG-5-5 - ANPR" },
  "IG-5-6": { short: "NoiPA", long: "IG-5-6 - NoiPA" },
  "IG-5-7": {
    short: "DB interesse nazionale",
    long: "IG-5-7 - DB di interesse nazionale",
  },
  "IG-5-8": { short: "Open Data", long: "IG-5-8 - Open Data" },
  "IG-5-9": {
    short: "Vocabolari controllati",
    long: "IG-5- 9 - Vocabolari controllati",
  },

  "IG-6-1": {
    short: "RNDT",
    long: "IG-6-1 - Repertorio nazionale dei dati territoriali  (RNDT)",
  },
  "IG-6-2": {
    short: "ANPR",
    long: "IG-6-2 - Anagrafe nazionale della popolazione residente (ANPR)",
  },
  "IG-6-3": {
    short: "BDNCP",
    long: "IG-6-3 - Banca dati nazionale dei contratti pubblici (BDNCP)",
  },
  "IG-6-4": {
    short: "Casellario Giudiziale",
    long: "IG-6-4 - Casellario Giudiziale",
  },
  "IG-6-5": { short: "RI", long: "IG-6-5 - Registro delle Imprese (RI)" },
  "IG-6-6": {
    short: "Archivi su immigrazione e asilo",
    long: "IG-6-6 - Archivi automatizzati in materia di immigrazione e asilo",
  },
  "IG-6-7": {
    short: "ANA",
    long: "IG-6-7 - Anagrafe nazionale degli assistiti (ANA)",
  },
  "IG-6-8": {
    short: "Anagrafe aziende agricole",
    long: "IG-6-8 - Anagrafe aziende agricole",
  },
  "IG-6-9": {
    short: "ANNCSU",
    long: "IG-6-9 - Archivio nazionale dei numeri civici delle strade urbane (ANNCSU)",
  },
  "IG-6-10": {
    short: "Base dati catastale",
    long: "IG-6-10 - Base dati catastale",
  },
  "IG-6-11": {
    short: "IPA",
    long: "IG-6-11 - Indice delle Pubbliche Amministrazioni (IPA)",
  },
  "IG-6-12": {
    short: "INI-PEC",
    long: "IG-6-12 - Indice nazionale degli indirizzi di posta elettronica certificata di professionisti e imprese (INI-PEC)",
  },
  "IG-6-13": {
    short: "PRA",
    long: "IG-6-13 - Pubblico registro automobilistico (PRA)",
  },
  "IG-6-14": {
    short: "Anagrafe Tributaria",
    long: "IG-6-14 - Anagrafe Tributaria",
  },
  "IG-6-15": {
    short: "Catalogo dati PA",
    long: "IG-6-15 - Catalogo dei dati delle Pubbliche Amministrazioni",
  },
  "IG-6-16": {
    short: "Catalogo servizi a cittadini e imprese",
    long: "IG-6-16 - Catalogo servizi a cittadini e imprese",
  },
  "IG-6-17": {
    short: "SINFI",
    long: "IG-6-17- Sistema informativo nazionale federato delle infrastrutture (SINFI)",
  },

  "IG-5": { short: "", long: "Infrastrutture immateriali integrate" },
  "IG-6": {
    short: "",
    long: "Integrazione con base dati di interesse nazionale",
  },
  "IG-7": {
    short: "",
    long: "Riuso di processi per erogazione servizi digitali",
  },
  "IG-8": { short: "", long: "Riuso soluzioni tecniche" },
  "IG-9": { short: "", long: "Collaborazione con altre amministrazioni" },

  "IM-1": {
    short: "",
    long: "Servizi di PMO",
    familyDescription:
      "Numerosità / Distribuzione dei Servizi di PMO nei contratti esecutivi attivati",
  },
  "IM-1-1": {
    short: "DT - Servizio S1",
    long: "PMO di programmi di digitalizzazione",
  },
  "IM-1-2": { short: "DT - Servizio S2", long: "PMO di progetti cross ambito" },
  "IM-1-3": {
    short: "DT - Servizio S3",
    long: "Supporto alla gestione dei progetti e dei programmi collegati alla Digital Transformation",
  },

  "IM-2": {
    short: "",
    long: "Composizione programmi di DT",
    familyDescription:
      "Informazione sul contenuto dei programmi di digitalizzazione attualmente in corso",
  },
  "IM-2-1": {
    short: "Gara DT - L1",
    long: "L1 - Gara Digital Transformation (ID 2069)",
  },
  "IM-2-2": {
    short: "Gara DT - L2",
    long: "L2 - Gara Digital Transformation (ID 2069)",
  },
  "IM-2-3": {
    short: "Gara DT - L3",
    long: "L3/4/5 - Gara Digital Transformation (ID 2069)",
  },
  "IM-2-4": {
    short: "Public Cloud Iaas e PaaS",
    long: "Gara Public Cloud IaaS e PaaS (ID 2213)",
  },
  "IM-2-5": {
    short: "SA Cloud",
    long: "Gara servizi Applicativi in ottica cloud (ID 2212)",
  },
  "IM-2-6": { short: "Data Mgmt", long: "Gara Data Management (ID 2102)" },
  "IM-2-7": {
    short: "Prodotti per la sicurezza",
    long: "Fornitura di prodotti per la sicurezza perimetrale, protezione degli endpoint e anti-apt ed erogazione di servizi connessi per le Pubbliche Amministrazioni (ID 2367),",
  },
  "IM-2-8": {
    short: "Prodotti per eventi di sicurezza",
    long: "Gara a procedura aperta per l’affidamento di un Accordo Quadro per la fornitura di prodotti per la gestione degli eventi di sicurezza e degli accessi, la protezione dei canali email, web e dati ed erogazione di servizi connessi per le Pubbliche Amministrazioni (ID 2174),",
  },
  "IM-2-9": {
    short: "Servizi sicurezza da remoto",
    long: "Servizi di sicurezza da remoto, di compliance e controllo per le Pubbliche Amministrazioni (ID 2296),",
  },
  "IM-2-10": {
    short: "Sanità digitale 1",
    long: "Sanità digitale 1 - sistemi informativi clinico assistenziali (ID 2202)",
  },
  "IM-2-11": {
    short: "Sanità digitale 2",
    long: "Sanità digitale 2 - sistemi informativi sanitari e servizi al cittadino (ID 2365)",
  },
  "IM-2-12": {
    short: "Sanità digitale 3",
    long: "Sanità digitale 3 - sistemi informativi gestionali (ID 2366)",
  },
  "IM-2-13": { short: "Public Cloud SaaS", long: "Public Cloud SaaS" },

  "IM-3": {
    short: "",
    long: "Ambiti di intervento",
    familyDescription:
      "Numerosità/Distribuzione degli ambiti di intervento nei contratti esecutivi attivati",
  },
  "IM-3-1": { short: "ACCESSO AI SERVIZI", long: "Accesso ai servizi" },
  "IM-3-2": { short: "ECOSISTEMI", long: "Ecosistemi" },
  "IM-3-3": { short: "PIATTAFORME", long: "Piattaforme" },
  "IM-3-4": { short: "DATI DELLA PA", long: "Dati della PA" },
  "IM-3-5": {
    short: "MODELLO DI INTEROPERABILITA",
    long: "Modello di interoperabilità",
  },
  "IM-3-6": { short: "INFRASTRUTTURE", long: "Infrastrutture" },
  "IM-3-7": { short: "SICUREZZA", long: "Sicurezza" },
  "IM-3-8": {
    short: "MODELLI E STRUMENTI PER L'INNOVAZIONE",
    long: "Strumenti per l'innovazione",
  },
  "IM-3-9": {
    short: "GESTIONE DEL TRANSIENTE",
    long: "Gestione del transiente",
  },

  "IM-4": {
    short: "",
    long: "Indicatori generali di digitalizzazione (IG)",
    familyDescription:
      "Numerosità/Distribuzione degli indicatori generali di digitalizzazione nei contratti esecutivi attivati",
  },
  "IM-4-1": {
    short: "IG 1",
    long: "Riduzione % della spesa per l’erogazione del servizio",
  },
  "IM-4-2": {
    short: "IG 2",
    long: "Riduzione % dei tempi di erogazione del servizio",
  },
  "IM-4-3": {
    short: "IG 3",
    long: "Numero servizi aggiuntivi offerti all’utenza interna, esterna cittadini, esterna impresa, altra PA",
  },
  "IM-4-4": {
    short: "IG 4",
    long: "Obiettivi CAD raggiunti con l’intervento ",
  },
  "IM-4-5": { short: "IG 5", long: "Infrastrutture immateriali integrate " },
  "IM-4-6": {
    short: "IG 6",
    long: "Integrazione con Basi Dati di interesse nazionale",
  },
  "IM-4-7": {
    short: "IG 7",
    long: "Riuso di processi per erogazione servizi digitali",
  },
  "IM-4-8": { short: "IG 8", long: "Riuso soluzioni tecniche" },
  "IM-4-9": {
    short: "IG 9",
    long: "Collaborazione con altre Amministrazioni (progetto in comune a più Amministrazioni)",
  },

  "IM-5": {
    short: "Giornate erogate",
    long: "Giornate erogate sul totale di giornate previste dai contratti esecutivi attivati ",
  },
  "IM-6": {
    short: "Capienza economica residua",
    long: "Capienza economica residua rispeto al budget totale dei contratti esecutivi attivati",
  },

  "IQ-1": {
    short: "Rispetto di una scadenza temporale",
    long: "L'indicatore valuta per ciascun deliverable/strumento di supporto, il rispetto di una scadenza (consegna/riconsegna di un deliverable della fornitura, esecuzione delle attività pianificate, ecc.) stabilita dalla documentazione contrattuale di Accordo Quadro e/o contratto esecutivo. Il valore indicato rappresenta il numero di contratti in cui l'indicatore assume un valore IQ01 <= 0",
  },
  "IQ-2": {
    short: "Qualità della documentazione",
    long: "L'indicatore valuta la rielaborazione documenti a seguito di richiesta da parte dell’Amministrazione. Il valore indicato rappresenta il numero di contratti in cui l'indicatore assume un valore IQ02 <= 1",
  },
  "IQ-3": {
    short: "Rispetto tempistiche di inserimento/sostituzione di personale",
    long: "L'indicatore valuta il tempo trascorso tra la richiesta Consip/ gli Organismi di monitoraggio e controllo/ le Amministrazione e l’inserimento o sostituzione della risorsa. Il valore indicato rappresenta il numero di contratti in cui l'indicatore assume un valore IQ03 <= 0",
  },
  "IQ-4": {
    short: "Inadeguatezza del personale proposto",
    long: "L'indicatore valuta l'inadeguatezza delle risorse proposte dal Fornitore Il valore indicato rappresenta il numero di contratti in cui l'indicatore assume un valore IQ04 = 0",
  },
  "IQ-5": {
    short: "Turn over del personale",
    long: "L'indicatore valuta il turn over: numero di risorse sostituite su iniziativa del Fornitore Il valore indicato rappresenta il numero di contratti in cui l'indicatore assume un valore IQ05 <= 1",
  },
  "IQ-6": {
    short: "Tempo di attivazione degli interventi",
    long: "L'indicatore valuta il tempo di attivazione degli interventi a partire dalla richiesta dell’Amministrazione Il valore indicato rappresenta il numero di contratti in cui l'indicatore assume un valore IQ06 <= 0",
  },
  "IQ-7": {
    short: "Numerosità dei rilievi sulla fornitura",
    long: "Viene valutato il numero di rilievi emessi per inadempimenti della fornitura: di carattere generale (pertanto tutti quelli sulla documentazione prodotta e non direttamente riconducibile ad un obiettivo); afferenti obbligazioni contrattuali non adempiute nei tempi e/o nei modi rappresentati nel contratto e suoi allegati e/o tracciati sui Piani di lavoro; afferenti il mancato rispetto delle soluzioni proposte in sede di offerta, così come indicato nel capitolato. Il valore indicato rappresenta il numero di contratti in cui l'indicatore assume un valore IQ07 <= 3",
  },
  "IQ-8": {
    short: "Non approvazione dei documenti fondamentali",
    long: "L'indicatore valuta la completezza, accuratezza funzionale, coerenza e stabilità funzionale. Rispetto degli standard e linee guida. Il valore indicato rappresenta il numero di contratti in cui l'indicatore assume un valore IQ08 = 0",
  },
  "IQ-9": {
    short: "Rispetto Pianificazione dell’Intervento",
    long: "L'indicatore valuta il rispetto della pianificazione dell’intervento. Il valore indicato rappresenta il numero di contratti in cui l'indicatore assume un valore IQ09 <= 0",
  },
  "IQ-10": {
    short: "Formatori inadeguati",
    long: "L'indicatore valuta il numero di formatori ritenuti inadeguati dall’Amministrazione.",
  },
  "IQ-11": {
    short: "Disponibilità del materiale didattico del corso",
    long: "L'indicatore valuta i giorni di ritardo rispetto alla data di disponibilità del materiale didattico prevista.",
  },
  "IQ-12": {
    short: "Soddisfazione degli utenti di formazione/affiancamento",
    long: "L'indicatore valuta la soddisfazione degli allievi che hanno partecipato a sessioni di formazione/affiancamento. La valutazione avverrà utilizzando numeri positivi in scala da 1 a 10 in cui: <=5 corrisponde a “non soddisfatto”; =6 corrisponde a “appena soddisfatto”; =7-8 corrisponde a “soddisfatto”; =9-10 corrisponde a “pienamente soddisfatto”.",
  },
  "IQ-13": {
    short: "Mancata segnalazione scostamenti",
    long: "L'indicatore valuta il numero di criticità emerse e non individuate/segnalate tempestivamente. Il valore indicato rappresenta il numero di contratti in cui l'indicatore assume un valore IQ13 <= 5",
  },
  "IQ-14": {
    short: "Rilievi sui servizi",
    long: "L'indicatore valuta il numero di rilievi emessi per non conformità inerenti tutti i servizi erogati nell’ambito di ciascun contratto esecutivo Il valore indicato rappresenta il numero di contratti in cui l'indicatore assume un valore IQ14 <= 2",
  },

  IS1: {
    short: "",
    long: "disponibilità piano economico-finanziario (collegato all’implementazione della strategia)",
    publicTender: "DT",
  },
  IS2: {
    short: "",
    long: "numero di linee del Piano Triennale indirizzate nella strategia rispetto al totale delle linee applicabili",
    publicTender: "DT",
  },
  IS3: {
    short: "",
    long: "numero di obiettivi pianificati a 3 anni sul totale obiettivi pianificati nella strategia",
    publicTender: "DT",
  },
  IS4: {
    short: "",
    long: "disponibilità piano economico-finanziario (collegato al Piano Strategico ICT),",
    publicTender: "DT",
  },
  IS5: {
    short: "",
    long: "efficientamento atteso della spesa ICT",
    publicTender: "DT",
  },
  IS6: {
    short: "",
    long: "% servizi digitali mappati rispetto al totale servizi digitali erogati dall’Amministrazione",
    publicTender: "DT",
  },
  IS7: {
    short: "",
    long: "Numero di nuovi servizi digitali mappati rispetto al totale dei servizi digitali erogati dall’Amministrazione.",
    publicTender: "DT",
  },
  IS8: {
    short: "",
    long: "% servizi digitali con modello di erogazione disegnato/censito rispetto al totale servizi digitali erogati dall’Amministrazione.",
    publicTender: "DT",
  },
  IS9: {
    short: "",
    long: " % servizi digitali con nuovo modello di erogazione rispetto al totale servizi digitali erogati dall’Amministrazione.",
    publicTender: "DT",
  },
  IS10: {
    short: "",
    long: "numero di processi digitali sottesi all’erogazione di servizi disegnati ex novo",
    publicTender: "DT",
  },
  IS11: {
    short: "",
    long: "numero di processi digitali reingegnerizzati",
    publicTender: "DT",
  },
  IS12: {
    short: "",
    long: "numero di servizi digitalizzati end to end per ogni milestone di pianificazione",
    publicTender: "DT",
  },
  IS13: {
    short: "",
    long: "Rapporto tra valore (spesa) per supporto e valore dell’intervento di disegno dei processi digitali per il quale si richiede supporto",
    publicTender: "DT",
  },
  IS14: {
    short: "",
    long: "Rapporto tra numero di processi digitali e numero di giornate di supporto acquistate",
    publicTender: "DT",
  },
  IS15: {
    short: "",
    long: "Rapporto tra valore (spesa) per supporto e valore dell’intervento di trasformazione per il quale l’Amministrazione richiede supporto",
    publicTender: "DT",
  },
  IS16: {
    short: "",
    long: "Rapporto tra Numero di strumenti di acquisizione valutati mediante l’attività di supporto e numero di giornate di supporto acquistate",
    publicTender: "DT",
  },
  IS17: {
    short: "",
    long: "% di utenti formati sul totale utenti previsti",
    publicTender: "DT",
  },
  IS18: {
    short: "",
    long: "livello di adozione del contenuto di trasformazione digitale",
    publicTender: "DT",
  },
  IS19: {
    short: "",
    long: "Produzione/condivisione/messa a disposizione di altre PP.AA. di flussi dati per analisi statisti-che/predittive",
    publicTender: "DT",
  },
  IS20: {
    short: "",
    long: "Numero di processi digitalizzati che usufruiscono dei dati aggregati prodotti e resi disponibili",
    publicTender: "DT",
  },
  IS21: {
    short: "",
    long: "Presenza di flussi di Integrazione/Scambio dati con PDND",
    publicTender: "DT",
  },
  IS22: {
    short: "",
    long: "Presenza di flussi di Integrazione/Scambio dati con basi dati di interesse nazionale",
    publicTender: "DM",
  },
  IS23: {
    short: "",
    long: "Presenza di flussi di popolamento del Catalogo nazionale dati.gov.it",
    publicTender: "DM",
  },
  IS24: {
    short: "",
    long: "Normalizzazione/standardizzazione ontologie e vocabolari in linea con gli obiettivi e le linee d’azione definite nel Piano Triennale AgID",
    publicTender: "DM",
  },
  IS25: {
    short: "",
    long: "Open Data: n° dataset pubblicati",
    publicTender: "DM",
  },
  IS26: {
    short: "",
    long: "Miglioramento Servizi digitalizzati: Nr servizi al cittadino-Impresa digitalizzati/Nr di servizi che richiedono interazione con il cittadino/imprese",
    publicTender: "DM",
  },
  IS27: {
    short: "",
    long: "Miglioramento dell’esperienza del cittadino/Impresa dei sistemi applicativi realizzati/modificati",
    publicTender: "DM",
  },
  IS28: {
    short: "",
    long: "Standardizzazione Strumenti per la generazione e diffusione dei servizi digitali: % componenti di navigazione e interfaccia standard ed usabili / totale componenti",
    publicTender: "DM",
  },
  IS29: {
    short: "",
    long: "Riusabilità-Co-working Soluzioni Applicative realizzate e/o adottate : Nr di progetti in riuso o Co-working /Nr Totale Progetti di digitalizzazione ove è applicabile il Riuso o Co-working",
    publicTender: "SAC",
  },
  IS30: {
    short: "",
    long: "Innalzamento livello di interoperabilità: Nr di progetti conformi alle Linee Guida di Interoperabilità e nel rispetto del ONCE ONLY Principle/ Nr Progetti Realizzati",
    publicTender: "SAC",
  },
  IS31: {
    short: "",
    long: "Potenziamento Infrastrutture IT - adozione sistematica del paradigma Cloud: Nr di progetti conformi al paradigma Cloud/ Nr Totale di progetti realizzati",
    publicTender: "SAC",
  },
  IS32: {
    short: "",
    long: "Utilizzo Piattaforme Abilitanti: Nr progetti che integrano Piattaforme Abilitanti/Nr progetti ove è applicabile un’integrazione con le Piattaforme Abilitanti",
    publicTender: "SAC",
  },
};
