import { Realm } from "@/models";
import { httpStore } from "@/store/typed";
import { CLOUD_ENABLING_BASE_API_URL } from "@/utils/Constants";
import qs from "qs";
import { Component, Vue } from "vue-property-decorator";
export enum RealmTags {
  RealmShow = "RealmShow",
  RealmIndex = "RealmIndex",
  RealmUpdate = "RealmUpdate",
  RealmByRefererCode = "RealmByRefererCode",
}
@Component
export default class RealmMixin extends Vue {
  readonly path = "/realms";
  readonly routes = this.$getModelRoutes(this.path);

  getRealms(page: number | null | string, filter: any) {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/realms`;
    const data = {
      page: page,
      filter: filter,
    };
    return httpStore.request({
      tag: RealmTags.RealmIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }) as Promise<Realm[]>;
  }

  getRealm(id: string) {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/realms/${id}`;

    return httpStore.request({
      tag: RealmTags.RealmIndex,
      url: url,
      method: "GET",
    }) as Promise<Realm>;
  }

  updateRealm(data: any) {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/realms/${data.id}`;

    return httpStore.request({
      tag: RealmTags.RealmUpdate,
      url: url,
      data: data,
      method: "PUT",
    }) as Promise<Realm>;
  }

  getByRefererCode() {
    const url = `${CLOUD_ENABLING_BASE_API_URL}/realms/by_referer_code`;

    return httpStore.request({
      tag: RealmTags.RealmByRefererCode,
      url: url,
      method: "GET",
    }) as Promise<Realm>;
  }
}
