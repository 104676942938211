import WatcherAreaData from "@/models/WatcherAreaData";
import { safeAsync } from "@/utils/AsyncUtil";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import IndicatorService from "@/service/IndicatorService";
import { IndicatorQualitative, IndicatorQuantitative } from "@/models";
import { json2xml } from "xml-js";

export type WatcherFilter = {
  organizationRegion?: string;
  organizationType?: string;
  organizationCategory?: string;
  initiativeStep?: string;
};

@Module({
  stateFactory: true,
  namespaced: false,
  name: "watcher",
})
export default class Watcher extends VuexModule {
  _filter: WatcherFilter = {};

  private _data = new WatcherAreaData();

  get filter(): WatcherFilter {
    return this._filter;
  }
  get organizationRegion(): string {
    return this._filter.organizationRegion;
  }
  get organizationType(): string {
    return this._filter.organizationType;
  }
  get organizationCategory(): string {
    return this._filter.organizationCategory;
  }
  get initiativeStep(): string {
    return this._filter.initiativeStep;
  }
  get data(): WatcherAreaData {
    return this._data;
  }

  @Mutation
  _reset() {
    this._filter = {};
  }

  @Action
  reset() {
    const { commit } = this.context;
    commit("_reset");
  }

  @Mutation
  _setFilter(payload: WatcherFilter) {
    this._filter = { ...this.filter, ...payload };
  }

  @Mutation
  _setData(payload: { value: any; key: string }) {
    (this._data as any)[payload.key] = payload.value;
  }

  @Action
  setFilter(payload: WatcherFilter) {
    const { commit } = this.context;
    commit("_setFilter", payload);
  }

  @Action
  exportData() {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.data)),
    );
    element.setAttribute("download", "export.json");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  @Action
  exportXmlData() {
    const element = document.createElement("a");

    const jsObject = { data: this.data };

    const jsonString = JSON.stringify(jsObject);
    const xmlFile = json2xml(jsonString, {
      compact: true,
      spaces: 4,
      ignoreDeclaration: false,
    });

    element.setAttribute(
      "href",
      "data:text/xml;charset=utf-8," + encodeURIComponent(xmlFile),
    );
    element.setAttribute("download", "export.xml");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  @Action
  async getQuantitativeData(payload: { code: string; category: string }) {
    const { commit } = this.context;

    const reqeustPayload = {
      filter: {
        code: payload.code,
        category: payload.category,
        ...this.filter,
      },
    };

    const [data, errors] = await safeAsync(
      IndicatorService.getQuantitative(reqeustPayload),
    );
    if (data) {
      const parsedData = IndicatorQuantitative.fromData(data);
      commit("_setData", { value: parsedData, key: "quantitativeIndicators" });
      return parsedData;
    } else {
      commit("_setData", { value: null, key: "quantitativeIndicators" });
      return null;
    }
  }

  @Action
  async getQualitativeData(payload: { code: string; category: string }) {
    const { commit } = this.context;

    const reqeustPayload = {
      filter: {
        code: payload.code,
        category: payload.category,
        ...this.filter,
      },
    };

    const [data, errors] = await safeAsync(
      IndicatorService.getQualitative(reqeustPayload),
    );
    if (data) {
      commit("_setData", { value: data, key: "qualitativeIndicators" });
      return data;
    } else {
      commit("_setData", { value: data, key: "qualitativeIndicators" });
      return null;
    }
  }

  @Action
  async getCollaborationData() {
    const { commit } = this.context;

    const reqeustPayload = {
      filter: {
        ...this.filter,
      },
    };

    const [data, errors] = await safeAsync(
      IndicatorService.getCollaborationReuseIndicators(reqeustPayload),
    );
    if (data) {
      commit("_setData", {
        value: data.indicators,
        key: "collaborationIndicators",
      });
      return data.indicators;
    } else {
      commit("_setData", {
        value: data.indicators,
        key: "collaborationIndicators",
      });
      return null;
    }
  }

  @Action
  async getDigitalIndicatorsData() {
    const { commit } = this.context;

    const reqeustPayload = {
      filter: {
        ...this.filter,
      },
    };

    const [data, errors] = await safeAsync(
      IndicatorService.getDigitalSpecificIndicators(reqeustPayload),
    );
    if (data) {
      commit("_setData", {
        value: data.indicators,
        key: "digitalSpecificIndicators",
      });
      return data.indicators;
    } else {
      commit("_setData", {
        value: data.indicators,
        key: "digitalSpecificIndicators",
      });
      return null;
    }
  }
}
