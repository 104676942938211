import { scroller } from "vue-scrollto/src/scrollTo";
import Vue from "vue";

const DEFAULT_OFFSET = 80;

class CustomScroller extends Vue {
  scrollOptions(): { offset: number } {
    let offset = this.$mq === "sm" || this.$mq === "md" ? 80 : 90;

    if (!offset) {
      offset = DEFAULT_OFFSET;
    }

    return { offset: -offset };
  }

  scroll(section: string) {
    const options = this.scrollOptions();

    if (section) {
      scroller()(section, options);
    }
  }
}

const customScroller = new CustomScroller();
export default customScroller;
