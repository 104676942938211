<template>
  <header class="it-header-wrapper primary-bg-a12">
    <mobile-slim-navbar :links="upperNavbarLinks" v-if="isMobile" />
    <div class="it-nav-wrapper">
      <slim-navbar
        :links="upperNavbarLinks"
        v-if="!isMobile && !toolRouteIsActive"
      />
      <div class="it-header-center-wrapper primary-bg-a12">
        <div class="container px-0">
          <div class="row">
            <div class="col-12 px-0">
              <div class="d-flex">
                <img :src="logo" class="it-brand-logo" v-if="!isMobile" />
                <div class="it-header-center-content-wrapper w-100">
                  <div
                    class="it-brand-wrapper pl-0 d-flex w-100 align-items-center"
                  >
                    <div class="it-brand-text pr-0">
                      <router-link
                        :to="{ name: 'home' }"
                        class="no_toc text-white text-left font-weight-semibold mb-1"
                        :class="isMobile ? 'font-size-20' : 'font-size-24'"
                      >
                        {{ pageTitle }}
                      </router-link>
                      <div class="d-flex">
                        <div
                          href="#"
                          class="badge badge-pill badge-white my-auto mr-3 text-uppercase"
                        >
                          {{ lotName }}
                        </div>
                        <p class="no_toc text-white font-size-14 my-auto">
                          {{ pageSubtitle }}
                        </p>
                      </div>
                    </div>
                    <button
                      class="custom-navbar-toggler ml-auto"
                      type="button"
                      aria-controls="nav02"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      data-target="#nav02"
                      v-if="isMobile && !toolRouteIsActive"
                      @click.stop.prevent="openSideMenu()"
                    >
                      <v-svg class="icon" code="it-burger" />
                    </button>
                    <div
                      class="ml-auto d-flex align-items-center"
                      v-if="toolRouteIsActive"
                    >
                      <div>
                        <v-svg class="icon icon-white" code="it-burger" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VueStickyElement
        visibleOnDirection="disabled"
        stuckClass="stuck"
        class="primary-bg-a12"
        v-if="!isMobile && !toolRouteIsActive"
      >
        <sticky-navbar
          :leftLinks="stickyNavbarLeftLinks"
          :rightLinks="stickyNavbarRightLinks"
        />
      </VueStickyElement>
      <mobile-side-menu ref="mobileSideMenu" :links="sideMenuLinks" />
    </div>
  </header>
</template>

<script lang="ts">
import { Vue, Component, Ref, Prop } from "vue-property-decorator";
import NavbarItem from "../../models/ui/NavbarItem";
import MobileSlimNavbar from "@/components/Common/MobileSlimNavbar.vue";
import { currentUserStore, zoneStore } from "../../store/typed";
import MobileSideMenu from "@/components/Mobile/SideMenu.vue";
import UserNavbarDropdown from "@/components/Common/UserNavbarDropdown.vue";
import SlimNavbar from "@/components/Common/SlimNavbar.vue";
import StickyNavbar from "@/components/Common/StickyNavbar.vue";
import { NoCache } from "@/utils/Decorators";
@Component({
  components: {
    MobileSideMenu,
    MobileSlimNavbar,
    SlimNavbar,
    StickyNavbar,
    UserNavbarDropdown,
  },
})
export default class TheNavbar extends Vue {
  @Ref() readonly mobileSideMenu!: MobileSideMenu;
  @Prop() toolRouteIsActive!: boolean;

  get logged() {
    return currentUserStore?.currentUser;
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  get lotName() {
    return zoneStore.zone?.badge_text;
  }

  get pageTitle() {
    return zoneStore.zone?.page_title;
  }

  get stickyNavbarRightLinks() {
    if (this.isHome) {
      return this.externalLinks;
    } else {
      return this.homeLink;
    }
  }

  get isHome() {
    return this.$isRouteActive("home");
  }

  get stickyNavbarLeftLinks() {
    if (this.logged && !this.isHome) {
      if (this.logged?.isOneOf(["admin", "superadmin"])) {
        return this.adminLinks;
      } else if (this.logged?.isOccUser || this.logged?.isConsipUser) {
        return this.consipOccUserLinks;
      } else if (this.logged?.isCompanyUser) {
        return this.rtiLinks;
      }
      return this.userLinks;
    } else {
      return this.publicLinks;
    }
  }

  get pageSubtitle() {
    return zoneStore.zone?.page_subtitle;
  }

  get sideMenuLinks(): NavbarItem[] {
    switch (this.$route.name) {
      case "home":
        return [...this.publicLinks, ...this.externalLinks];
      case "faq":
        return [...this.publicLinks, ...this.externalLinks];
      case "info_home":
        if (this.logged?.isOneOf(["admin", "superadmin", "company_user"])) {
          return [...this.infoPageLinks, ...this.adminLinks];
        } else if (this.logged.isOccUser) {
          return [
            this.watcherAreaLink,
            {
              label: "Area fornitura",
              route: { name: "monitoring_post_feed" },
              activeRoutes: [
                "monitoring_post_feed",
                "monitoring_initiative_map",
              ],
              active: false,
              hidden: false,
            },
          ];
        }
        return [...this.userLinks, ...this.infoPageLinks];
      default:
        if (this.logged?.isOneOf(["admin", "superadmin", "company_user"])) {
          return [...this.adminLinks];
        } else if (this.logged?.isOccUser) {
          return [this.watcherAreaLink];
        } else {
          return [...this.publicLinks];
        }
    }
  }

  private homeLink: NavbarItem[] = [
    {
      label: "Torna alla home",
      route: { name: "home" },
      icon: "home",
      active: false,
    },
  ];

  private secureHomeLink: NavbarItem[] = [
    {
      label: "Torna all'area riservata",
      route: { name: "info_home" },
      active: false,
    },
  ];

  private upperNavbarLinks: NavbarItem[] = [
    {
      label: "Contattaci",
      route: { name: "contact-us" },
      active: false,
    },
    {
      label: "Chi siamo",
      route: { name: "chi-siamo" },
      active: false,
    },
  ];

  private externalLinks: NavbarItem[] = [
    {
      label: "FAQ",
      route: { name: "faq" },
      active: false,
      separatorBefore: true,
    },
  ];

  get watcherAreaLink(): any {
    return {
      label: "Area Osservatori",
      route: { name: "watcher_area_home" },
      activeRoutes: [],
      active: false,
    };
  }

  private adminLinks: NavbarItem[] = [
    {
      label: "Configurazione servizi",
      route: { name: "estimate_requests_list" },
      active: false,
      separatorBefore: true,
    },
    {
      label: "Attivazione Iniziative",
      route: { name: "admin_initiatives_list" },
      activeRoutes: ["admin_initiative_show", "initiative_show"],
      active: false,
    },
    {
      label: "Gestione Iniziative",
      route: { name: "admin_contracts_list" },
      activeRoutes: ["admin_contract_show", "contract_show"],
      active: false,
    },
    {
      label: "Area monitoraggio",
      route: { name: "monitoring_post_feed" },
      activeRoutes: ["monitoring_post_feed", "monitoring_initiative_map"],
      active: false,
      hidden: false,
    },
    this.watcherAreaLink,
  ];

  private rtiLinks: NavbarItem[] = [
    {
      label: "Configurazione servizi",
      route: { name: "estimate_requests_list" },
      active: false,
      separatorBefore: true,
    },
    {
      label: "Attivazione Iniziative",
      route: { name: "admin_initiatives_list" },
      activeRoutes: ["admin_initiative_show", "initiative_show"],
      active: false,
    },
    {
      label: "Gestione Iniziative",
      route: { name: "admin_contracts_list" },
      activeRoutes: ["admin_contract_show", "contract_show"],
      active: false,
    },
  ];

  private userLinks: NavbarItem[] = [
    {
      label: "Area informativa",
      route: { name: "info_home" },
      activeRoutes: [
        "info_configurator",
        "documents",
        "event_list",
        "service_show",
      ],
      active: false,
    },
    {
      label: "Project Management",
      route: { name: "project_management_home" },
      activeRoutes: ["project_management_home"],
      active: false,
    },
    {
      label: "Collaborazione e monitoraggio",
      route: { name: "monitoring_post_feed" },
      activeRoutes: ["monitoring_post_feed", "monitoring_initiative_map"],
      active: false,
      hidden: false,
    },
  ];

  private consipOccUserLinks: NavbarItem[] = [
    {
      label: "Project Management",
      route: { name: "project_management_home" },
      activeRoutes: ["project_management_home"],
      active: false,
    },
    {
      label: "Collaborazione e monitoraggio",
      route: { name: "monitoring_post_feed" },
      activeRoutes: ["monitoring_post_feed", "monitoring_initiative_map"],
      active: false,
      hidden: false,
    },
    {
      label: "Area Osservatori",
      route: { name: "watcher_area_home" },
      activeRoutes: [],
      active: false,
    },
  ];

  private publicLinks: NavbarItem[] = [
    {
      label: "Accordo quadro",
      route: { name: "home" },
      section: "#framework-agreement",
      active: false,
    },
    {
      label: "I numeri",
      route: { name: "home" },
      section: "#dashboard",
      active: false,
    },
    {
      label: "News",
      route: { name: "home" },
      section: "#news",
      active: false,
    },
    {
      label: "Video Pills",
      route: { name: "home" },
      section: "#video-pills",
      active: false,
    },
  ];

  private infoPageLinks: NavbarItem[] = [
    {
      label: "Modello operativo",
      route: { name: "home_info" },
      section: "#business-model",
      active: false,
    },
    {
      label: "Catalogo servizi",
      route: { name: "home_info" },
      section: "#services-catalogue",
      active: false,
    },
    {
      label: "Configuratore",
      route: { name: "home_info" },
      section: "#configurator",
      active: false,
    },
    {
      label: "Biblioteca digitale",
      route: { name: "home_info" },
      section: "#digital-library",
      active: false,
    },
    {
      label: "Eventi",
      route: { name: "home_info" },
      section: "#events",
      active: false,
    },
    {
      label: "PA Digital Stories",
      route: { name: "home_info" },
      section: "#digital-stories",
      active: false,
    },
  ];

  get logo() {
    return require("@/assets/icons/logo.png");
  }

  private openSideMenu() {
    this.mobileSideMenu?.show();
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/vue";

.it-header-center-wrapper,
.it-header-navbar-wrapper {
  background: initial;
}

.it-brand-logo {
  max-width: 50px;
  max-height: 52px;
}

.it-header-wrapper {
  width: 100%;

  @include media-breakpoint-down(lg) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }
}

.it-header-navbar-wrapper {
  &.stuck {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    z-index: $zindex-sticky;
    width: 100%;
    background-color: #001a33;
  }
}
a.nav-link {
  font-size: 14px !important;
}

.login-button {
  min-width: 150px;
}

.it-brand-text {
  h2 {
    font-size: 26px;
  }
}
</style>
