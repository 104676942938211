import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Parser } from "@/models/Model";
import RequestData from "@/store/types/RequestData";
import { CLOUD_ENABLING_BASE_API_URL } from "@/utils/Constants";

@Module({
  stateFactory: true,
  namespaced: true,
  name: "http",
})
export default class Http extends VuexModule {
  private _status: { [key: string]: RequestData } = {};

  get status(): { [key: string]: RequestData } {
    return this._status;
  }

  get registered() {
    return (key: string) => this._status[key] !== undefined;
  }

  @Mutation
  register(payload: string) {
    if (this._status[payload]) {
      return;
    }

    Vue.set(this._status, payload, {
      loading: false,
      statusCode: 0,
      errors: null,
    });
  }

  @Mutation
  clear(payload: string) {
    Vue.set(this._status, payload, {
      loading: false,
      statusCode: 0,
      errors: null,
    });
  }

  @Mutation
  setError(payload: { tag: string; errors: any }) {
    Vue.set(this._status[payload.tag], "errors", payload.errors);
  }

  @Mutation
  setResponseData({
    tag,
    response,
    isError,
  }: {
    tag: string;
    response: AxiosResponse<any>;
    isError: boolean;
  }) {
    this._status[tag].statusCode = response?.status || -1;
    if (!isError) {
      Vue.set(this._status[tag], "meta", response.data.meta);
      Vue.set(this._status[tag], "links", response.data.links);
      return;
    }

    if (response?.data && response?.data?.errors) {
      Vue.set(this._status[tag], "errors", response?.data);
    } else {
      Vue.set(this._status[tag], "errors", {
        message: response?.statusText || "Unknown Error",
      });
    }
  }

  @Mutation
  setLoading({ tag, loading }: { tag: string; loading: boolean }) {
    this._status[tag].loading = loading;
  }

  @Action({ commit: "register" })
  dispatchRegister(tag: string) {
    return tag;
  }

  @Action({ commit: "clear" })
  dispatchClear(tag: string) {
    return tag;
  }

  @Action({ rawError: true })
  async request(
    config: AxiosRequestConfig & { tag: string; noParse?: boolean },
  ) {
    const { commit, getters } = this.context;

    //FIXME : make it better
    const { tag } = config;
    const { noParse } = config;
    const isApi =
      config.url.includes("/api/") || config.url.includes("ppd-api.test");

    if (!getters.registered(tag)) {
      commit("register", tag);
    }
    commit("setLoading", { tag, loading: true });

    try {
      const response = await axios(config);
      commit("setResponseData", { tag, response, isError: false });
      if (isApi && !noParse) {
        return Parser.parse(response.data);
      } else {
        return response.data;
      }
    } catch (err) {
      const { response } = err as AxiosError;
      commit("setResponseData", { tag, response, isError: true });
      throw err;
    } finally {
      commit("setLoading", { tag, loading: false });
    }
  }
}
