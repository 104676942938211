export default {
  // FIXME: to Inter ts's thirst of clean code I had to cast this to any
  methods: {
    $isRouteActive(route: string): boolean {
      return !!(this as any).$route.matched.find((e: any) => e.name === route);
    },
    $areRouteActive(routes: string[]): boolean {
      return !!(this as any).$route.matched.some((e: any) =>
        routes.includes(e.name),
      );
    },
  },
};
